import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { ImageSlide, fetchImageSlideApi } from "./imageSlideApi"

export interface ImageSlideState {
  imageUrls: ImageSlide[]
  status: "idle" | "loading" | "rejected"
}

const initialState: ImageSlideState = {
  imageUrls: [],
  status: "idle",
}

export const getImageSlidesAsync = createAsyncThunk(
  "image/slides",
  async () => {
    const response = await fetchImageSlideApi()
    return response
  },
)

export const imageSlideSlice = createSlice({
  name: "imageSlide",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getImageSlidesAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getImageSlidesAsync.fulfilled, (state, action) => {
        state.status = "idle"

        state.imageUrls = action.payload
      })
      .addCase(getImageSlidesAsync.rejected, (state) => {
        state.status = "rejected"
      })
  },
})

export default imageSlideSlice.reducer
