import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { axiosInstance } from "../../app/service/api"

interface Support {
  id: number
  phoneNumbers: string[]
  createdAt: string
  updatedAt: string
}

export const getSupportApi = async () => {
  try {
    const response = await axiosInstance.get<Support[]>("support")

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getSupportAsync = createAsyncThunk("support", async () => {
  try {
    const response = await getSupportApi()
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
})

interface SupportState {
  status: "idle" | "loading" | "rejected"
  error: string | undefined
  support: Support[]
}

const initialState: SupportState = {
  status: "loading",
  error: undefined,
  support: [],
}

const supportSlice = createSlice({
  name: "support",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getSupportAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getSupportAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.support = action.payload
      })
      .addCase(getSupportAsync.rejected, (state, action) => {
        state.status = "rejected"
        state.error = action.error.message
      })
  },
})

export default supportSlice.reducer
