import { Link } from "react-router-dom"
import { Pagination } from "../../components/Pagination"
import LoadingSpinner from "../../components/loading"
import { getCompleteImageUrl } from "../../utils/helpers"
import { useCheckout } from "./useCheckout"
import { useTranslation } from "react-i18next"

const Orders = () => {
  const { t } = useTranslation()
  const { ustatus, ucheckoutResponse, page, setPage } = useCheckout()

  if (ustatus === "loading") {
    return (
      <div className="flex items-center justify-center h-screen">
        <LoadingSpinner />
      </div>
    )
  }

  if (ucheckoutResponse.length === 0) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-center p-10 text-2xl">
          {t("You don't have any orders yet")}
        </p>
      </div>
    )
  }

  return (
    <div className="mt-10 mx-auto w-full max-w-screen-md mb-10 px-4">
      <p className="text-2xl my-10">{t("Orders")}</p>
      <div className="space-y-6 ">
        {ucheckoutResponse.map((order, index) => {
          return (
            <div className=" bg-white p-4 rounded-lg shadow-md" key={index}>
              <Link to="/ordersDetail" state={order}>
                <div className="flex items-center  gap-10">
                  <img
                    src={getCompleteImageUrl(
                      order.Products[0].imageUrl.split(",")[0],
                    )}
                    alt={"order"}
                    className="w-24 h-24 object-cover rounded-full"
                  />
                  <div className="md:flex items-center justify-between gap-10">
                    <div className="md:space-y-2">
                      <p>
                        <strong>{t("Items")}:</strong> {order.Products.length}
                      </p>
                      <p>
                        <strong>{t("Total Price")}: </strong> {order.totalPrice}{" "}
                        ETB
                      </p>
                    </div>
                    <div className="md:space-y-2">
                      <p>
                        <strong>{t("Deliver to")}: </strong>
                        {order.deliveryAddress?.name}
                      </p>
                      <p>
                        <strong>{t("Status")}: </strong>
                        {order.status}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          )
        })}

        <section className="flex w-full justify-end">
          <Pagination
            previous={page > 1}
            next={ucheckoutResponse.length > 9}
            loadPreviousPage={() => setPage((prev) => prev - 1)}
            loadNextPage={() => setPage((prev) => prev + 1)}
          />
        </section>
      </div>
    </div>
  )
}

export default Orders
