import { useNavigate, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { useEffect, useState } from "react"
import { getProductsAsyc } from "./productThunk"
import LoadingSpinner from "../../components/loading"
import { getCompleteImageUrl } from "../../utils/helpers"
import { addToCart } from "../Cart/cartSlice"
import { Pagination } from "../../components/Pagination"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"

export default function Product() {
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { products, status } = useAppSelector((state) => state.product)
  const [nextPage, setNextPage] = useState(1)

  useEffect(() => {
    dispatch(getProductsAsyc(nextPage))
  }, [dispatch, id, nextPage])

  if (status === "loading") {
    return (
      <div className="flex items-center justify-center h-screen">
        <LoadingSpinner />
      </div>
    )
  }

  if (products.length === 0) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-center p-10 text-2xl">{t("Product not found")}</p>
      </div>
    )
  }

  return (
    <div>
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <h2 className="text-2xl font-bold tracking-tight text-gray-900">
          {t("New Product")}
        </h2>

        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8 ">
          {products.map((product) => (
            <div key={product.createdAt} className="group relative">
              <div
                onClick={() => navigate(`/product/${product.id}`)}
                className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-80 shadow-md"
              >
                <img
                  src={getCompleteImageUrl(product.imageUrl[0])}
                  alt={product.name}
                  className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                />
              </div>
              <div className="mt-4 space-y-2">
                <div>
                  <h3 className="text-sm text-gray-700">
                    <p className="text-ellipsis line-clamp-1 text-start  font-bold">
                      {product.name}
                    </p>

                    <p className="text-ellipsis line-clamp-1 text-start  font-light">
                      {product.description}
                    </p>
                  </h3>
                </div>
                <div className="flex mt-1 justify-between mr-1">
                  <p className="text-sm font-bold text-gray-900  ">
                    {product.price}
                  </p>

                  <div className=" px-5 py-[5px] bg-blue-900 rounded-[10px] justify-between items-center inline-flex">
                    <button
                      onClick={() => {
                        if (!product.isInStock) {
                          toast.info(t("Product is out of stock"), {
                            position: toast.POSITION.TOP_RIGHT,
                          })
                          return
                        }
                        dispatch(addToCart(product))

                        toast.success(t("Added to cart"), {
                          position: toast.POSITION.TOP_RIGHT,
                        })
                      }}
                      className="text-white  font-bold "
                    >
                      {t("Order Now")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex w-full justify-end">
          <Pagination
            previous={nextPage > 1}
            next={products?.length > 9}
            loadPreviousPage={() => setNextPage((prev) => prev - 1)}
            loadNextPage={() => setNextPage((prev) => prev + 1)}
          />
        </div>
      </div>
    </div>
  )
}
