import { useTranslation } from "react-i18next"
import Container from "../../components/Container"
import { useNavigate } from "react-router-dom"
import PEButton from "../../components/Input/PEPutton"
import { useState } from "react"

export const ForgetPassword = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [view, setView] = useState({
    fp: true,
    vo: false,
    cp: false,
  })

  const onForgetPassword = () => {
    setView({ fp: false, vo: true, cp: false })
  }
  const onVerifyOTP = () => {
    setView({ fp: false, vo: false, cp: true })
  }
  const onChangePassword = () => {
    setView({ fp: true, vo: false, cp: false })
  }

  return (
    <Container>
      <div className="mt-10  mx-auto w-full max-w-screen-sm flex flex-col h-screen justify-center">
        <div className="bg-white p-10 rounded-lg shadow-lg space-y-4">
          <h1 className="text-4xl">{t("Forget Password")}</h1>
          {view.fp && (
            <>
              <label
                htmlFor="name"
                className="block  leading-6 font-light mb-4 "
              >
                {t("Phone number")}
              </label>
              <div className="flex">
                <div className="w-[58px] h-10 px-4 py-[15px] bg-blue-900 rounded-[5px] justify-center items-center  inline-flex mr-2">
                  <div className="text-white text-base font-medium font-['Inter']">
                    +251
                  </div>
                </div>
                <input
                  className="p-2 px-4 text-start  border border-primary w-full placeholder:text-xs placeholder:text-start md:text-left placeholder:md:text-left focus:outline-none rounded-md focus:ring-primary focus:ring-1"
                  placeholder={t("Enter your phone number")}
                />
              </div>
            </>
          )}
          {view.vo && (
            <>
              <p className="text-xl">
                {t(
                  "Please input the one time password code you have received via sms",
                )}
              </p>

              <label
                htmlFor="name"
                className="block  leading-6 font-light mb-4 "
              >
                OTP
              </label>

              <input
                className="p-2 px-4 text-start  border border-primary w-full placeholder:text-xs placeholder:text-start md:text-left placeholder:md:text-left focus:outline-none rounded-md focus:ring-primary focus:ring-1"
                placeholder={t("****")}
              />
              <div className="flex justify-end gap-2">
                <p>{t("Didn't get code ?")}</p>
                <button className="text-primary">{t("Resend OTP")}</button>
              </div>
            </>
          )}
          {view.cp && (
            <>
              <label
                htmlFor="name"
                className="block  leading-6 font-light mb-4 "
              >
                {t("Change Password")}
              </label>

              <input
                className="p-2 px-4 text-start  border border-primary w-full placeholder:text-xs placeholder:text-start md:text-left placeholder:md:text-left focus:outline-none rounded-md focus:ring-primary focus:ring-1"
                placeholder={t("Enter password")}
              />

              <input
                className="p-2 px-4 text-start  border border-primary w-full placeholder:text-xs placeholder:text-start md:text-left placeholder:md:text-left focus:outline-none rounded-md focus:ring-primary focus:ring-1"
                placeholder={t("Confirm password")}
              />
            </>
          )}

          <PEButton
            name={
              view.vo
                ? t("Verify OTP")
                : view.cp
                ? t("Change password")
                : t("Forgot Password")
            }
            type={"button"}
            onClick={() => {
              if (view.cp) onChangePassword()

              if (view.fp) onForgetPassword()

              if (view.vo) onVerifyOTP()
            }}
          />
        </div>
      </div>
    </Container>
  )
}
