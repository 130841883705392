import React, { useState, ChangeEvent } from "react"

interface ImageUploadProps {
  onImagesChange: (images: File[]) => void
}

const UploadMultipleImage: React.FC<ImageUploadProps> = ({
  onImagesChange,
}) => {
  const [selectedImages, setSelectedImages] = useState<File[]>([])

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files

    if (files) {
      const selectedFiles: File[] = Array.from(files)
      setSelectedImages((prevSelectedImages) => [
        ...prevSelectedImages,
        ...selectedFiles,
      ])
      onImagesChange([...selectedImages, ...selectedFiles])
    }
  }

  // const handleRemoveImage = (index: number) => {
  //   const updatedImages = [...selectedImages]
  //   updatedImages.splice(index, 1)
  //   setSelectedImages(updatedImages)
  //   onImagesChange(updatedImages)
  // }

  return (
    <div>
      <label htmlFor="image-upload" className="cursor-pointer">
        <div className="w-full py-3 rounded-md overflow-hidden border-2 bg-primary">
          <div className="text-center">
            <span className="text-white">Add Multiple image</span>
          </div>
        </div>
      </label>
      <input
        id="image-upload"
        type="file"
        multiple
        accept="image/*"
        className="hidden"
        onChange={handleFileChange}
      />
      {/* {selectedImages.length > 0 && (
        <div>
          <h3>Selected Images:</h3>

          <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
            {selectedImages.map((file, index) => (
              <div key={index} className="relative">
                <img
                  src={URL.createObjectURL(file)}
                  alt={file.name}
                  className="h-36 w-36 object-contain "
                />
                <button
                  className="absolute top-2 right-2 bg-red-500 text-white p-2 rounded-full cursor-pointer"
                  onClick={() => handleRemoveImage(index)}
                >
                  Remove
                </button>
              </div>
            ))}
          </div>
        </div>
      )} */}
    </div>
  )
}

export default UploadMultipleImage
