import { createSlice } from "@reduxjs/toolkit"
import { CreditCategory, CreditResponse } from "./creditApi"
import { addCreditAsync, getCreditCategoryAsync } from "./creditThunk"
import { toast } from "react-toastify"

interface CreditCategoryState {
  status: "idle" | "loading" | "rejected"
  error: string | undefined
  creditCategory: CreditCategory[]
  creditResponse: CreditResponse | null
}

const initialState: CreditCategoryState = {
  status: "idle",
  error: undefined,
  creditCategory: [],
  creditResponse: null,
}

const creditSlice = createSlice({
  name: "credit",
  initialState,
  reducers: {
    resetCreditState: (state) => {
      state.status = "idle"
      state.error = undefined
      state.creditCategory = []
      state.creditResponse = null
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getCreditCategoryAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getCreditCategoryAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.creditCategory = action.payload
      })
      .addCase(getCreditCategoryAsync.rejected, (state, action) => {
        state.status = "rejected"
        state.error = action.error.message
      })
      .addCase(addCreditAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(addCreditAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.creditResponse = action.payload

        toast.success("Apply for credit success", {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .addCase(addCreditAsync.rejected, (state, action) => {
        state.status = "rejected"
        state.error = action.error.message
      })
  },
})

export const { resetCreditState } = creditSlice.actions

export default creditSlice.reducer
