import { createSlice } from "@reduxjs/toolkit"
import { getAboutOnBoardingAsyc } from "./aboutOnBoardingThunk"
import { AboutOnBoarding } from "./aboutOnBoardingApi"

interface CourseState {
  status: "idle" | "loading" | "rejected"
  error: string | undefined
  aboutOnBoarding: AboutOnBoarding[]
}

const initialState: CourseState = {
  status: "idle",
  error: undefined,
  aboutOnBoarding: [],
}

const aboutOnBoardingSlice = createSlice({
  name: "aboutOnBoarding",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getAboutOnBoardingAsyc.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getAboutOnBoardingAsyc.fulfilled, (state, action) => {
        state.status = "idle"
        state.aboutOnBoarding = action.payload
      })
      .addCase(getAboutOnBoardingAsyc.rejected, (state, action) => {
        state.status = "rejected"
        state.error = action.error.message
      })
  },
})

export default aboutOnBoardingSlice.reducer
