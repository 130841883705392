import { useForm, SubmitHandler } from "react-hook-form"
import { useAppDispatch, useAppSelector } from "../../app/hooks"

import { useLocation, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { Credit } from "./creditApi"
import { addCreditAsync } from "./creditThunk"
import { useEffect, useMemo, useState } from "react"
import { getUser } from "../../utils/helpers"
import useUploadImage from "../ImageUpload/useUploadImage"
import { resetCreditState } from "./creditSlice"

function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

export const useCreditHook = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { status, creditResponse } = useAppSelector((state) => state.credit)

  const [image, setImage] = useState<File[]>([])

  const handleRemoveImage = (indexToRemove: number) => {
    setImage((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove),
    )
  }

  let query = useQuery()
  const { id } = useParams()
  const creditType = query.get("type")

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Credit>()

  let [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  useEffect(() => {
    if (creditResponse) {
      navigate("/credit")
      dispatch(resetCreditState())
    }
  }, [creditResponse, dispatch, navigate])

  const {
    status: uploadImageStatus,
    uploadResult,
    error: uploadImageError,
    uploadImage,
    uploadMultipleImage,
    mstatus,
    muploadResult,
  } = useUploadImage()

  const handleMultipleFile = (files: File[]) => {
    setImage(files)
  }

  const uploadImg = () => {
    uploadMultipleImage(image)
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files
    if (file) {
      uploadImage(file)
    }
  }

  const onSubmit: SubmitHandler<Credit> = (data: Credit) => {
    let user = getUser()

    if (muploadResult) {
      data.imageUrls = muploadResult.filenames
    }

    if (data.imageUrls.length === 0) {
      return toast.error(
        "Please upload your ID documents or passport so we can verify your identity",
        {
          position: toast.POSITION.TOP_RIGHT,
        },
      )
    }

    data.creditType = creditType ?? ""
    data.userId = user.id
    data.isApplied = true
    data.creditCategoryId = id ?? ""

    dispatch(addCreditAsync(data))
  }

  return {
    register,
    handleSubmit,
    onSubmit,
    handleFileChange,
    closeModal,
    openModal,
    errors,
    status,
    uploadImageStatus,
    uploadResult,
    uploadImageError,
    id,
    creditType,
    isOpen,
    mstatus,
    muploadResult,
    handleMultipleFile,
    uploadImg,
    image,
    handleRemoveImage,
  }
}
