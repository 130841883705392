import { useNavigate } from "react-router-dom"
import Button from "../../components/Input/button"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { useEffect } from "react"
import { getCreditCategoryAsync } from "./creditThunk"
import { getCompleteImageUrl } from "../../utils/helpers"
import LoadingSpinner from "../../components/loading"
import { useTranslation } from "react-i18next"

const CreditCategory = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { creditCategory, status } = useAppSelector((state) => state.credit)

  useEffect(() => {
    dispatch(getCreditCategoryAsync())
  }, [dispatch])

  if (status === "loading") {
    return (
      <div className="flex items-center justify-center h-screen">
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <div className="mt-10  mx-auto w-full max-w-screen-md flex flex-col ">
      <div className="flex h-full justify-center items-center">
        <div className="h-36 w-36 rounded-[100px] bg-secondary p-4 my-6">
          <img src="/logo.png" alt="power ethio" />
        </div>
      </div>
      {creditCategory.map((credit) => {
        return (
          <div
            key={credit.createdAt}
            className="shadow-md p-4  rounded-lg bg-white mt-6"
            onClick={() =>
              navigate(`/credit/${credit.id}?type=${credit.creditType}`)
            }
          >
            <div className="md:flex">
              <img
                src={getCompleteImageUrl(credit.imageUrl)}
                alt="power"
                className="object-cover h-36 w-full md:w-44 rounded-lg"
              />

              <div className=" mt-4 ml-4">
                <h2 className="font-bold text-xl text-start">{credit.title}</h2>
                <p className="line-clamp-3 text-ellipsis text-justify">
                  {credit.description}
                </p>
                <div className="hidden md:block md:w-1/2">
                  <Button btnName={t("Apply now")} />
                </div>
              </div>
            </div>
            <div className="md:hidden">
              <Button btnName={t("Apply now")} />
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default CreditCategory
