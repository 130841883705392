import { createSlice } from "@reduxjs/toolkit"
import { registerAsync } from "./registerThunk"
import { RegisterResponse } from "./registerAPI"

interface RegisterState {
  status: "idle" | "loading" | "rejected"
  error: string | undefined
  userInfo: null | RegisterResponse
  city: string | null
}

const initialState: RegisterState = {
  status: "idle",
  error: undefined,
  userInfo: null,
  city: null,
}

export const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    addCity: (state, action) => {
      state.city = action.payload
    },
    resetRegisterState: (state) => {
      state.status = "idle"
      state.error = undefined
      state.userInfo = null
      state.city = null
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(registerAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(registerAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.userInfo = action.payload
      })
      .addCase(registerAsync.rejected, (state, action) => {
        state.status = "rejected"
        state.error = action.error.message
      })
  },
})

export const { addCity, resetRegisterState } = registerSlice.actions

export default registerSlice.reducer
