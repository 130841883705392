import { createSlice } from "@reduxjs/toolkit"
import { Product } from "./productApi"
import {
  getProductByIdAsyc,
  getProductsAsyc,
  searchProductAsyc,
} from "./productThunk"

interface CourseState {
  status: "idle" | "loading" | "rejected"
  error: string | undefined
  products: Product[]
  product: Product | null
}

const initialState: CourseState = {
  status: "idle",
  error: undefined,
  products: [],
  product: null,
}

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getProductsAsyc.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getProductsAsyc.fulfilled, (state, action) => {
        state.status = "idle"
        state.products = action.payload
      })
      .addCase(getProductsAsyc.rejected, (state, action) => {
        state.status = "rejected"
        state.error = action.error.message
      })

      .addCase(searchProductAsyc.pending, (state) => {
        state.status = "loading"
      })
      .addCase(searchProductAsyc.fulfilled, (state, action) => {
        state.status = "idle"
        state.products = action.payload
      })
      .addCase(searchProductAsyc.rejected, (state, action) => {
        state.status = "rejected"
        state.error = action.error.message
      })

      .addCase(getProductByIdAsyc.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getProductByIdAsyc.fulfilled, (state, action) => {
        state.status = "idle"
        state.product = action.payload
      })
      .addCase(getProductByIdAsyc.rejected, (state, action) => {
        state.status = "rejected"
        state.error = action.error.message
      })
  },
})

export default productsSlice.reducer
