import axios from "axios"
import { axiosInstance } from "../../app/service/api"
import { IRegisterForm } from "./useRegisterHook"
import { toast } from "react-toastify"

export interface RegisterResponse {
  id: number
  fullName: string
  phoneNumber: string
  country: string
  description: string
  imageUrl: string
  status: string
  role: string
}

export const registerApi = async (formData: IRegisterForm) => {
  try {
    const response = await axiosInstance.post<RegisterResponse>(
      "/auth/register",
      formData,
    )

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
    throw error
  }
}
