import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getToken, getUser } from "../../utils/helpers"
import { axiosInstance } from "../../app/service/api"
import { toast } from "react-toastify"

export interface DeliveryResponse {
  id: number
  address: string
  city: string
  name: string
  phoneNumber: string
  userId: number
  createdAt: string
  updatedAt: string
}

export interface Delivery {
  address: string
  city: string
  name: string
  phoneNumber: string
  userId: number
}

interface DeliveryState {
  status: "idle" | "loading" | "rejected"
  dstatus: "idle" | "loading" | "rejected"
  rstatus: "idle" | "loading" | "rejected"
  error: string | undefined
  derror: string | undefined
  deliveryResponse: DeliveryResponse[]
  deliveryRes: DeliveryResponse | undefined
  removeDeliveryRes: string | null
  city: string | null
}

const initialState: DeliveryState = {
  status: "idle",
  dstatus: "idle",
  rstatus: "idle",
  error: undefined,
  derror: undefined,
  deliveryResponse: [],
  deliveryRes: undefined,
  removeDeliveryRes: null,
  city: null,
}

export const getDeliveryApi = async () => {
  const token = getToken()
  const user = getUser()
  try {
    const response = await axiosInstance.get<DeliveryResponse[]>(
      `deliveryAddress/user/${user.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const addDeliveryApi = async (formData: Delivery) => {
  const token = getToken()
  try {
    const response = await axiosInstance.post<DeliveryResponse>(
      "/deliveryAddress",
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const removeDeliveryApi = async (id: number) => {
  const token = getToken()
  try {
    const response = await axiosInstance.delete(`/deliveryAddress/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const addDeliveryAsync = createAsyncThunk(
  "deliveryAddress",
  async (formData: Delivery) => {
    try {
      const response = await addDeliveryApi(formData)
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  },
)

export const removeDeliveryAsync = createAsyncThunk(
  "deliveryAddress/remove",
  async (id: number) => {
    try {
      const response = await removeDeliveryApi(id)
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  },
)

export const getDeliveryAsync = createAsyncThunk("delivery", async () => {
  try {
    const response = getDeliveryApi()
    return response
  } catch (error) {
    throw error
  }
})

const deliverySlice = createSlice({
  name: "delivery",
  initialState,
  reducers: {
    addCity: (state, action) => {
      state.city = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getDeliveryAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getDeliveryAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.deliveryResponse = action.payload
      })
      .addCase(getDeliveryAsync.rejected, (state, action) => {
        state.status = "rejected"
        state.error = action.error.message
      })

      .addCase(addDeliveryAsync.pending, (state) => {
        state.dstatus = "loading"
      })
      .addCase(addDeliveryAsync.fulfilled, (state, action) => {
        state.dstatus = "idle"
        state.deliveryRes = action.payload
        toast.success("Delivery added success", {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .addCase(addDeliveryAsync.rejected, (state, action) => {
        state.status = "rejected"

        state.derror = action.error.message
      })

      .addCase(removeDeliveryAsync.pending, (state) => {
        state.rstatus = "loading"
      })
      .addCase(removeDeliveryAsync.fulfilled, (state, action) => {
        state.rstatus = "idle"
        state.removeDeliveryRes = action.payload
        toast.success("Delivery removed success", {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .addCase(removeDeliveryAsync.rejected, (state, action) => {
        state.status = "rejected"

        state.derror = action.error.message
      })
  },
})

export const { addCity } = deliverySlice.actions

export default deliverySlice.reducer
