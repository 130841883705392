import { axiosInstance } from "../../app/service/api"
import { getUser } from "../../utils/helpers"

export interface User {
  id: number
  fullName: string
  phoneNumber: string
  country: string
  description: string
  imageUrl: string
  status: string
  token?: any
  role: string
  isLoggedIn?: any
  isVerified?: any
  otp?: any
  subscriptionStartDate: string
  subscriptionEndDate: string
  createdAt: string
  updatedAt: string
}
export const getProfileApi = async () => {
  let user = getUser()

  try {
    const response = await axiosInstance.get<User>(`auth/user/${user.id}`, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
