import { createSlice } from "@reduxjs/toolkit"
import i18n from "i18next"

const initialState = {
  language: "am",
  translations: {},
}

const langSlice = createSlice({
  name: "lang",
  initialState,
  reducers: {
    setLanguage(state, action) {
      state.language = action.payload
      localStorage.setItem("language", action.payload)
      i18n.changeLanguage(action.payload)
    },
    loadTranslations(state, action) {
      state.translations = action.payload
      i18n.changeLanguage(action.payload)
    },
  },
})

export const { setLanguage, loadTranslations } = langSlice.actions
export default langSlice.reducer
