import { createSlice } from "@reduxjs/toolkit"
import { Training } from "./trainingApi"
import { getTrainingByCategoryIdAsync } from "./trainingThunk"

interface TrainingState {
  status: "idle" | "loading" | "rejected"
  error: string | undefined
  trainings: Training[]
}

const initialState: TrainingState = {
  status: "idle",
  error: undefined,
  trainings: [],
}

export const trainingSlice = createSlice({
  name: "training",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getTrainingByCategoryIdAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getTrainingByCategoryIdAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.trainings = action.payload
      })
      .addCase(getTrainingByCategoryIdAsync.rejected, (state, action) => {
        state.status = "rejected"
        state.error = action.error.message
      })
  },
})

export default trainingSlice.reducer
