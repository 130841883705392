import { createSlice } from "@reduxjs/toolkit"
import { About } from "./aboutApi"
import { getAboutAsyc } from "./aboutThunk"

interface AboutState {
  status: "idle" | "loading" | "rejected"
  error: string | undefined
  about: About[]
}

const initialState: AboutState = {
  status: "idle",
  error: undefined,
  about: [],
}

const aboutSlice = createSlice({
  name: "about",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getAboutAsyc.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getAboutAsyc.fulfilled, (state, action) => {
        state.status = "idle"
        state.about = action.payload
      })
      .addCase(getAboutAsyc.rejected, (state, action) => {
        state.status = "rejected"
        state.error = action.error.message
      })
  },
})

export default aboutSlice.reducer
