import { useForm, SubmitHandler } from "react-hook-form"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { useEffect, useState } from "react"
import { getProfileAsync } from "../Profile/profileSlice"
import { updateProfileAsync } from "./updateProfileThunk"
import useUploadImage from "../ImageUpload/useUploadImage"

export interface IUpdateProfileForm {
  fullName: string
  phoneNumber: string
  country: string
  description: string
  imageUrl: any
  password: string
}
export const useUpdateProfileHook = () => {
  const dispatch = useAppDispatch()
  const [isAgreed, setIsAgreed] = useState(false)
  const { user } = useAppSelector((state) => state.profile)
  const { status } = useAppSelector((state) => state.updateProfile)
  const [image, setImage] = useState<File[]>([])

  const {
    status: uploadImageStatus,
    uploadResult,
    error: uploadImageError,
    uploadImage,
    uploadMultipleImage,
    mstatus,
    muploadResult,
  } = useUploadImage()

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IUpdateProfileForm>()

  useEffect(() => {
    dispatch(getProfileAsync())
  }, [dispatch])

  useEffect(() => {
    if (user) {
      setValue("fullName", user.fullName)
      setValue("phoneNumber", user.phoneNumber)
      setValue("country", user.country)
      setValue("description", user.description)
      setValue("imageUrl", user.imageUrl)
    }
  }, [setValue, user])

  const onSubmit: SubmitHandler<IUpdateProfileForm> = (
    data: IUpdateProfileForm,
  ) => {
    if (muploadResult) {
      data.imageUrl = muploadResult.filenames
    }
    dispatch(updateProfileAsync(data))
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files
    if (file) {
      uploadImage(file)
    }
  }

  const handleMultipleFile = (files: File[]) => {
    setImage(files)
  }

  const uploadImg = () => {
    uploadMultipleImage(image)
  }

  const handleRemoveImage = (indexToRemove: number) => {
    setImage((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove),
    )
  }

  return {
    register,
    handleSubmit,
    onSubmit,
    setIsAgreed,
    handleFileChange,
    handleMultipleFile,
    uploadImg,
    handleRemoveImage,
    errors,
    status,
    isAgreed,
    uploadImageStatus,
    uploadResult,
    uploadImageError,
    mstatus,
    image,
  }
}
