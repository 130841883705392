import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Product } from "../Product/productApi"
import { toast } from "react-toastify"

interface CartState {
  products: Product[]
}

const cartData = localStorage.getItem("cart")
const initialState: CartState = {
  products: cartData ? JSON.parse(cartData) : [],
}

const cartSlice = createSlice({
  name: "cart",
  initialState: initialState,
  reducers: {
    removeAllFromCart: () => {
      localStorage.setItem("cart", JSON.stringify([]))
    },
    resetCartState: (state) => {
      state.products = []
    },
    addToCart: (state, action: PayloadAction<Product>) => {
      const existingProduct = state.products.find(
        (item) => item.id === action.payload.id,
      )

      if (!existingProduct) {
        state.products.push(action.payload)

        localStorage.setItem("cart", JSON.stringify(state.products))
      } else {
        toast.info("Product already in cart", {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
    },
    removeFromCart: (state, action: PayloadAction<Product>) => {
      const index = state.products.findIndex(
        (item: Product) => item.id === action.payload.id,
      )
      if (index !== -1) {
        state.products = state.products.filter((_, idx) => idx !== index)

        localStorage.setItem("cart", JSON.stringify(state.products))
      }
    },
  },
})

export const { addToCart, removeFromCart, removeAllFromCart, resetCartState } =
  cartSlice.actions

export default cartSlice.reducer
