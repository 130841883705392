import React from "react"
import ReactDOM from "react-dom/client"
import i18n from "./features/localization/i18n"
import { store } from "./app/store"
import { Provider } from "react-redux"
import { ToastContainer } from "react-toastify"
import { I18nextProvider } from "react-i18next"
import "./index.css"
import "react-toastify/dist/ReactToastify.css"

import { App } from "./App"

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <ToastContainer />
        <App />
      </I18nextProvider>
    </Provider>
  </React.StrictMode>,
)
