import Container from "../../components/Container"
import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import LoadingSpinner from "../../components/loading"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import PDFViewer from "../../components/PdfReader"
import { getAboutAsyc } from "./aboutThunk"
import PEButton from "../../components/Input/PEPutton"
import PDFReader from "../../components/ReactPDFReader"
import { getCompleteImageUrl } from "../../utils/helpers"

const About = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { about, status } = useAppSelector((state) => state.about)

  useEffect(() => {
    dispatch(getAboutAsyc())
  }, [dispatch])

  if (status === "loading") {
    return <LoadingSpinner />
  }

  if (about.length === 0) {
    return <p className="text-center p-10 text-2xl">About not found</p>
  }

  return (
    <Container>
      <div className="text-center text-2xl  py-6">{about[0].title}</div>

      <div className="my-10 mx-2">
        <div className=" md:hidden">
          <PDFReader url={getCompleteImageUrl(about[0].pdfUrl)} />
        </div>
        <div className="hidden md:block">
          <PDFViewer pdfUrl={about[0].pdfUrl} />
        </div>
      </div>

      <PEButton
        name={t("Visit our website")}
        type={"button"}
        onClick={() => navigate("/")}
      />
    </Container>
  )
}

export default About
