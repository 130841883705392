import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { useEffect } from "react"

import LoadingSpinner from "../../components/loading"
import { baseUrl } from "../../utils/constants"
import { getImageSlidesAsync } from "./imageSlideSlice"

const ImageSlide = () => {
  const dispatch = useAppDispatch()
  const { imageUrls, status } = useAppSelector((state) => state.imageSlide)

  useEffect(() => {
    dispatch(getImageSlidesAsync())
  }, [dispatch])

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  if (status === "loading") {
    return <LoadingSpinner />
  }

  return (
    <div>
      <Slider {...settings} arrows={false} className="h-48 md:h-80 mt-10">
        {imageUrls.map((image) => {
          return (
            <div key={image.id}>
              <img
                src={`${baseUrl}/uploads/${image.imageUrls[0]}`}
                alt="slide"
                className="h-48 md:h-80 w-screen object-cover object-center rounded-lg"
              />
            </div>
          )
        })}
      </Slider>
    </div>
  )
}

export default ImageSlide
