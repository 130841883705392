import { createSlice } from "@reduxjs/toolkit"
import { updateProfileAsync } from "./updateProfileThunk"
import { UpdateProfileResponse } from "./updateProfileAPI"
import { toast } from "react-toastify"

interface RegisterState {
  status: "idle" | "loading" | "rejected"
  error: string | undefined
  user: UpdateProfileResponse | null
}

const initialState: RegisterState = {
  status: "idle",
  error: undefined,
  user: null,
}

export const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(updateProfileAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(updateProfileAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.user = action.payload
        toast.success("Profile update success", {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .addCase(updateProfileAsync.rejected, (state, action) => {
        state.status = "rejected"
        state.error = action.error.message
      })
  },
})

export default registerSlice.reducer
