import { Dialog, Transition } from "@headlessui/react"
import { Fragment } from "react"

import { useTranslation } from "react-i18next"
import Button from "../../components/Input/button"
import { Feedback, addFeedbackAsyc } from "./feedbackSlice"
import { SubmitHandler, useForm } from "react-hook-form"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import PEButton from "../../components/Input/PEPutton"
import { getUser } from "../../utils/helpers"
import PETextarea from "../../components/PETextarea"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"

export default function FeedbackModal(props: any) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { status } = useAppSelector((state) => state.feedback)

  const Feedbackchema = z.object({
    description: z.string().min(1, t("Field cannot be empty")),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Feedback>({
    resolver: zodResolver(Feedbackchema),
  })

  const onSubmit: SubmitHandler<Feedback> = (data: Feedback) => {
    const user = getUser()
    data.userId = user.id
    dispatch(addFeedbackAsyc(data))
  }

  return (
    <>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10 flex flex-col justify-center items-center"
          onClose={props.closeModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6  align-middle shadow-xl transition-all text-center">
                  <Dialog.Title className="text-xl font-bold mb-6">
                    {t("Customer Feedback")}
                  </Dialog.Title>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <PETextarea
                      name={"description"}
                      placeholder={t("We would love to hear from you...")}
                      register={register}
                      label={""}
                      errors={errors}
                    />
                    {/* <textarea
                      id="description"
                      placeholder={t("We would love to hear from you...")}
                      className="p-2 px-4 text-start border border-primary w-full placeholder:text-xs placeholder:text-start md:text-left placeholder:md:text-left focus:outline-none rounded-md focus:ring-primary focus:ring-1"
                      {...register("description")}
                    /> */}
                    {status === "loading" ? (
                      <p className="py-4">Loading...</p>
                    ) : (
                      <PEButton name={t("Send feedback")} type={"submit"} />
                    )}
                  </form>
                  <Button btnName={t("Close")} onClick={props.closeModal} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
