import { createBrowserRouter } from "react-router-dom"

import Cart from "../features/Cart/Cart"
import Course from "../features/Course/Course"
import Product from "../features/Product/Product"
import Training from "../features/Training/Training"
import Home from "../features/home/Home"
import Login from "../features/login/Login"
import Register from "../features/register/Register"
import AboutOnBoarding from "../features/AboutOnBoarding/AboutOnBoarding"
import Navbar from "../components/Navbar"
import Language from "../features/Language/Language"
import RegistrationPayment from "../features/RegistrationPayment/RegistrationPayment"
import ProductDetail from "../features/ProductDetail/ProductDetail"
import CreditCategory from "../features/Credit/CreditCategory"
import Credit from "../features/Credit/Credit"
import Checkout from "../features/Checkout/Checkout"
import Profile from "../features/Profile/Profile"
import UpdateProfile from "../features/UpdateProfile/UpdateProfile"
import Delivery from "../features/Delivery/Delivery"
import { loader } from "../routing/ProtectedRoute"
import Notifications from "../features/Notification/Notification"
import About from "../features/About/About"
import TermsAndCondition from "../features/TermsAndConditions/TermsAndCondition"
import Orders from "../features/Checkout/Orders"
import OrdersDetail from "../features/Checkout/OrdersDetail"
import ErrorBoundary from "../components/ErrorBoundary"
import { ForgetPassword } from "../features/ForgetPassword/ForgetPassword"

export const router = createBrowserRouter([
  {
    loader: loader,
    children: [
      {
        path: "/",
        element: <Navbar />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            path: "/",
            element: <Home />,
          },

          {
            path: "/cart",
            element: <Cart />,
          },
          {
            path: "/product",
            children: [
              {
                path: "/product",
                element: <Product />,
              },
              {
                path: "/product/:id",
                element: <ProductDetail />,
              },
            ],
          },
          {
            path: "/training/:id",
            element: <Training />,
          },
          {
            path: "/course/:id",
            element: <Course />,
          },
          {
            path: "/credit",
            children: [
              {
                path: "/credit",
                element: <CreditCategory />,
              },
              {
                path: "/credit/:id",
                element: <Credit />,
              },
            ],
          },
          {
            path: "/credit",
            element: <CreditCategory />,
          },
          {
            path: "/checkout",
            element: <Checkout />,
          },
          {
            path: "/delivery",
            element: <Delivery />,
          },
          {
            path: "/profile",
            children: [
              {
                path: "/profile",
                element: <Profile />,
              },
              {
                path: "/profile/update",
                element: <UpdateProfile />,
              },
            ],
          },

          {
            path: "/notification",
            element: <Notifications />,
          },
          {
            path: "/orders",
            element: <Orders />,
          },
          {
            path: "/ordersDetail",
            element: <OrdersDetail />,
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    errorElement: <ErrorBoundary />,
    element: <Login />,
  },

  {
    path: "/register",
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/register/payment",
        element: <RegistrationPayment />,
      },
    ],
  },

  {
    path: "/aboutOnBoarding",
    errorElement: <ErrorBoundary />,
    element: <AboutOnBoarding />,
  },

  {
    path: "/forgetPassword",
    errorElement: <ErrorBoundary />,
    element: <ForgetPassword />,
  },

  {
    path: "/language",
    errorElement: <ErrorBoundary />,
    element: <Language />,
  },

  {
    path: "/about",
    errorElement: <ErrorBoundary />,
    element: <About />,
  },

  {
    path: "/termsAndConditions",
    errorElement: <ErrorBoundary />,
    element: <TermsAndCondition />,
  },
])
