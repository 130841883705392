/* eslint-disable jsx-a11y/no-redundant-roles */

import { useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { useEffect, useState } from "react"
import { getProductByIdAsyc } from "../Product/productThunk"
import LoadingSpinner from "../../components/loading"
import { getCompleteImageUrl } from "../../utils/helpers"
import PEButton from "../../components/Input/PEPutton"
import { addToCart } from "../Cart/cartSlice"
import VideoModal from "../../components/Modal/VideoModal"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"

export default function ProductDetail() {
  const { t } = useTranslation()
  const { id } = useParams()
  const dispatch = useAppDispatch()

  const [image, setImage] = useState<string | null>(null)
  const [isOpen, setIsOpen] = useState(false)

  const { product, status } = useAppSelector((state) => state.product)

  useEffect(() => {
    dispatch(getProductByIdAsyc(Number(id)))
  }, [dispatch, id])

  if (status === "loading" || !product) {
    return <LoadingSpinner />
  }
  return (
    <div>
      <VideoModal
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
        videoUrl={product.videoUrl}
      />
      <div className="pt-6 mx-4">
        <div className="mx-auto max-w-6xl">
          <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl ">
            {product.name}
          </h1>
          <div className=" md:flex gap-4">
            <div className="grow h-64 md:h-96">
              <img
                src={image ?? getCompleteImageUrl(product.imageUrl[0])}
                alt={product.name}
                className="h-full w-full object-cover object-center rounded-xl"
              />
            </div>

            <div className="hidden md:block my-4">
              <p className="text-2xl">{t("Product price")}</p>
              <p className="font-bold">{product.price} Birr</p>
              <PEButton
                name={t("Order Now")}
                type={"button"}
                onClick={() => {
                  if (!product.isInStock) {
                    toast.info(t("Product is out of stock"), {
                      position: toast.POSITION.TOP_RIGHT,
                    })
                    return
                  }
                  dispatch(addToCart(product))

                  toast.success(t("Added to cart"), {
                    position: toast.POSITION.TOP_RIGHT,
                  })
                }}
              />
            </div>
          </div>

          <div className="grid grid-cols-3 md:grid-cols-8 gap-2 my-2">
            {product.imageUrl.map((image, index) => {
              return (
                <button
                  key={index}
                  className="mx-auto max-w-24 hover:cursor-pointer"
                  onClick={() => setImage(getCompleteImageUrl(image))}
                >
                  <img
                    src={getCompleteImageUrl(image)}
                    alt={image}
                    className="h-20 md:h-full w-full object-cover object-center rounded-lg"
                  />
                </button>
              )
            })}
          </div>

          <div className="md:hidden my-4">
            <p className="text-2xl">Product price</p>
            <p className="font-bold">{product.price} Birr</p>
            <PEButton
              name={"Order now"}
              type={"button"}
              onClick={() => {
                if (!product.isInStock) {
                  toast.info(t("Product is out of stock"), {
                    position: toast.POSITION.TOP_RIGHT,
                  })
                  return
                }
                dispatch(addToCart(product))

                toast.success(t("Added to cart"), {
                  position: toast.POSITION.TOP_RIGHT,
                })
              }}
            />
          </div>
          <div className="flex w-full justify-center mt-10">
            <button
              type="button"
              className="mt-6  rounded-md bg-primary px-3 py-4 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
              onClick={() => setIsOpen(true)}
            >
              {t("Watch video")}
            </button>
          </div>
        </div>

        <div className="mx-auto max-w-6xl mt-10 mb-10 px-4">
          <div className="flex w-full justify-between ">
            <p>{t("Descriptions")}</p>
            <p>
              {t("Available in stock")} {product.isInStock ? "✅" : "❌"}{" "}
            </p>
          </div>

          <div className="mt-10">
            <h3 className="sr-only">{t("Descriptions")}</h3>

            <div className="space-y-6">
              <p className="text-base text-gray-900 text-justify">
                {product.description}
              </p>
            </div>
          </div>
          <div className="my-10 space-y-2">
            <p>
              <strong>{t("Get Support")}</strong>
            </p>
            <p>
              {t(
                "Call our support center for more information about this product",
              )}
            </p>
            <div className="mt-6">
              <a href="tel:+251-973-234-137">+251-973-234-137</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
