import React from "react"
import { useTranslation } from "react-i18next"

import { SocialIcon } from "react-social-icons/component"
import "react-social-icons/facebook"
import "react-social-icons/tiktok"
import "react-social-icons/youtube"
import "react-social-icons/linkedin"
import { useNavigate } from "react-router-dom"

const Footer = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <footer className="bg-primary p-10 text-white mt-10 text-xl">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="space-y-4 md:space-y-0 md:flex w-full md:justify-between">
          <div className="col-4">
            <h3 className="footer-title">{t("About Power Ethio")}</h3>
            {/* <p>Power Ethio is a .....</p> */}
            <button
              className="text-gray-300 "
              onClick={() => navigate("/about")}
            >
              Learn More
            </button>
          </div>
          <div className="col-4">
            <h3 className="footer-title">{t("Connect with Us")}</h3>
            <div className="flex space-x-4 my-4">
              <SocialIcon
                url="www.facebook.com"
                href="https://www.facebook.com/profile.php?id=100067020143602"
                target="_blank"
              />
              <SocialIcon
                url="www.linkedin.com"
                href="https://www.linkedin.com/company/power-ethiopia/?viewAsMember=true"
                target="_blank"
              />
              <SocialIcon
                url="www.tiktok.com"
                href="https://www.tiktok.com/@powerethiopia"
                target="_blank"
              />

              <SocialIcon
                url="www.youtube.com"
                href="https://youtube.com/@Powerethiopia?si=YCyOAIEFKUnbzhi8"
                target="_blank"
              />
            </div>
          </div>
          <div className="col-4">
            <h3 className="footer-title">{t("Contact Us")}</h3>
            <div className="flex flex-col">
              <a href="tel:+251-911-866-977">+251-911-866-977</a>
              <a href="mailto:powerethio@gmail.com">powerethio@gmail.com</a>
            </div>
          </div>
        </div>

        <div className="mt-10 copyright md:text-center text-sm text-start ">
          <p>{t("© 2024 Power Ethio. All rights reserved.")}</p>
          <p>Power by Skylink Trading PLC</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
