import { FC } from "react"
import PEButton from "./Input/PEPutton"
import { useTranslation } from "react-i18next"

interface PaginationProps {
  previous: boolean | null
  next: boolean | null
  loadPreviousPage: () => void
  loadNextPage: () => void
}

export const Pagination: FC<PaginationProps> = ({
  previous,
  next,
  loadPreviousPage,
  loadNextPage,
}) => {
  const { t } = useTranslation()
  return (
    <div className="flex gap-3 justify-end">
      {previous && (
        <PEButton
          onClick={loadPreviousPage}
          type="button"
          name={t("Previous")}
        />
      )}
      {next && (
        <PEButton onClick={loadNextPage} type="button" name={t("Next")} />
      )}
    </div>
  )
}
