import { axiosInstance } from "../../app/service/api"
import { getToken, getUser } from "../../utils/helpers"

export interface CheckoutResponse {
  id: number
  totalPrice: string
  productIds: string
  paymentId: string
  paymentImageUrl: string
  deliveryLocation: string
  status: string
  userId: number
  updatedAt: string
  createdAt: string
}

export interface Checkout {
  totalPrice: string
  products: Products[]
  paymentId: string
  paymentImageUrl: string
  deliveryAddressId: number
  userId: number
}

export interface Products {
  id: number
  quantity: number
}

export interface ICreditResponse {
  id: number
  totalPrice: string
  paymentId: string
  paymentImageUrl: string
  status: string
  userId: number
  deliveryAddressId: number
  createdAt: string
  updatedAt: string
  Products: Product[]
  deliveryAddress: DeliveryAddress
}

interface DeliveryAddress {
  id: number
  address: string
  city: string
  name: string
  phoneNumber: string
  userId: number
  createdAt: string
  updatedAt: string
}

interface Product {
  id: number
  name: string
  imageUrl: string
  videoUrl: string
  isInStock: boolean
  description: string
  pdfUrl: string
  price: string
  shortDescription: string
  createdAt: string
  updatedAt: string
  ProductCheckout: ProductCheckout
}

interface ProductCheckout {
  quantity: number
  createdAt: string
  updatedAt: string
  CheckoutId: number
  ProductId: number
}

export const addCheckoutApi = async (formData: Checkout) => {
  const token = getToken()
  try {
    const response = await axiosInstance.post<CheckoutResponse>(
      "checkout",
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getCheckoutApi = async (page: number) => {
  const token = getToken()
  const user = getUser()
  try {
    const response = await axiosInstance.get<ICreditResponse[]>(
      `checkout/user/${user.id}?page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
