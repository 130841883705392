import { createSlice } from "@reduxjs/toolkit"
import { TermsAndCondition } from "./termsAndConditionApi"
import { getTermsAndConditionAsyc } from "./termsAndConditionThunk"

interface TermsAndConditionState {
  status: "idle" | "loading" | "rejected"
  error: string | undefined
  termsAndConditions: TermsAndCondition[]
}

const initialState: TermsAndConditionState = {
  status: "idle",
  error: undefined,
  termsAndConditions: [],
}

const termsAndConditionsSlice = createSlice({
  name: "termsAndConditions",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getTermsAndConditionAsyc.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getTermsAndConditionAsyc.fulfilled, (state, action) => {
        state.status = "idle"
        state.termsAndConditions = action.payload
      })
      .addCase(getTermsAndConditionAsyc.rejected, (state, action) => {
        state.status = "rejected"
        state.error = action.error.message
      })
  },
})

export default termsAndConditionsSlice.reducer
