import Container from "../../components/Container"
import PETextinput from "../../components/Input/Input"
import PEButton from "../../components/Input/PEPutton"
import PETextarea from "../../components/PETextarea"
import LoadingSpinner from "../../components/loading"
import { useUpdateProfileHook } from "./useUpdateProfileHook"
import UploadMultipleImage from "../ImageUpload/uploadMultipleImage"
import { useTranslation } from "react-i18next"
import { FC } from "react"

export default function UpdateProfile() {
  const { t } = useTranslation()
  const {
    onSubmit,
    register,
    uploadImg,
    handleSubmit,
    handleMultipleFile,
    handleRemoveImage,
    errors,
    status,
    mstatus,
    image,
  } = useUpdateProfileHook()

  return (
    <Container>
      <div className="mt-10 mx-auto w-full max-w-screen-md mb-10">
        <p className="text-center my-6 text-2xl">{t("Update profile")} </p>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-6 bg-white p-4 rounded-lg shadow-xl md:p-10 "
        >
          <PETextinput
            type={"text"}
            name={"fullName"}
            label={t("Full name")}
            placeholder={t("Enter your full name")}
            register={register}
            errors={errors}
          />

          <PETextinput
            type={"number"}
            name={"phoneNumber"}
            label={t("Phone number")}
            placeholder={t("Enter your phone number")}
            register={register}
            errors={errors}
          />

          <PETextinput
            type={"text"}
            name={"country"}
            label={t("Country")}
            placeholder={t("Enter your Country")}
            register={register}
            errors={errors}
          />

          <PETextinput
            type={"text"}
            name={"password"}
            label={t("Password")}
            placeholder={t("**********")}
            register={register}
            errors={errors}
          />

          <PETextarea
            name={"description"}
            register={register}
            placeholder={t("Enter your job description")}
            label={t("Description")}
            errors={errors}
          />

          <div className="text-justify text-neutral-800 text-[12px] font-normal ">
            {t(
              "Please upload your ID documents or passport so we can verify your identity",
            )}
          </div>

          <ImageUpload
            image={image}
            mstatus={mstatus}
            handleRemoveImage={handleRemoveImage}
            uploadImg={uploadImg}
            handleMultipleFile={handleMultipleFile}
          />

          {status === "loading" ? (
            <LoadingSpinner />
          ) : (
            <PEButton name={t("Update profile")} type={"submit"} />
          )}
        </form>
      </div>
    </Container>
  )
}

interface IImageUpload {
  image: File[]
  mstatus: "idle" | "loading" | "rejected"
  handleRemoveImage: (indexToRemove: number) => void
  uploadImg: () => void
  handleMultipleFile: (files: File[]) => void
}

export const ImageUpload: FC<IImageUpload> = ({
  image,
  handleRemoveImage,
  mstatus,
  uploadImg,
  handleMultipleFile,
}) => {
  return (
    <>
      {image.length > 0 && (
        <div className="flex ">
          {image.map((img, index) => {
            const imageUrl = URL.createObjectURL(img)
            return (
              <div className="relative" key={index}>
                <img src={imageUrl} alt="" height={100} width={100} />
                <button
                  className="absolute top-0 right-0 h-6 w-6 bg-white shadow-md rounded-full text-red-500"
                  onClick={() => handleRemoveImage(index)}
                >
                  X
                </button>
              </div>
            )
          })}
        </div>
      )}

      {mstatus === "loading" ? (
        <LoadingSpinner />
      ) : (
        <>
          {image.length > 0 ? (
            <PEButton
              name={"Upload images"}
              type={"button"}
              onClick={uploadImg}
            />
          ) : (
            <UploadMultipleImage onImagesChange={handleMultipleFile} />
          )}
        </>
      )}
    </>
  )
}
