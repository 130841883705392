import { toast } from "react-toastify"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { uploadImageAsync, uploadMultipleImageAsync } from "./uploadImageSlice"

const useUploadImage = () => {
  const dispatch = useAppDispatch()
  const { status, uploadResult, error, mstatus, muploadResult } =
    useAppSelector((state) => state.uploadImage)

  const uploadImage = async ([imageFile]: FileList) => {
    if (!imageFile || !imageFile.type.startsWith("image/")) {
      return toast.warning("Please upload a valid image", {
        position: toast.POSITION.TOP_RIGHT,
      })
    }

    try {
      await dispatch(uploadImageAsync(imageFile))
    } catch (error) {
      return toast.error(
        `Error while uploading image please try again later ${error}`,
        {
          position: toast.POSITION.TOP_RIGHT,
        },
      )
    }
  }

  const uploadMultipleImage = async (files: File[]) => {
    await dispatch(uploadMultipleImageAsync(files))
  }

  return {
    status,
    uploadResult,
    error,
    mstatus,
    muploadResult,
    uploadImage,
    uploadMultipleImage,
  }
}

export default useUploadImage
