import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import Container from "../../components/Container"
import PEButton from "../../components/Input/PEPutton"
import LoadingSpinner from "../../components/loading"
import {
  Delivery as IDelivery,
  addDeliveryAsync,
  getDeliveryAsync,
  removeDeliveryAsync,
} from "./deliverySlice"
import PETextinput from "../../components/Input/Input"
import { SubmitHandler, useForm } from "react-hook-form"
import { getUser } from "../../utils/helpers"
import { useTranslation } from "react-i18next"
import CountryDropdown from "../Country/Country"
import { toast } from "react-toastify"

export default function Delivery() {
  const { t } = useTranslation()

  const {
    status,
    deliveryResponse,
    deliveryRes,
    dstatus,
    rstatus,
    removeDeliveryRes,
    city,
  } = useAppSelector((state) => state.delivery)

  const { status: cstatus } = useAppSelector((state) => state.country)

  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getDeliveryAsync())
  }, [deliveryRes, dispatch, removeDeliveryRes])

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IDelivery>()

  const onSubmit: SubmitHandler<IDelivery> = (data: IDelivery) => {
    const user = getUser()
    data.userId = user.id

    if (!city) {
      return toast.error(t("Please choose your city"), {
        position: toast.POSITION.TOP_RIGHT,
      })
    }

    data.city = city
    dispatch(addDeliveryAsync(data))
    reset()
  }

  return (
    <Container>
      <div className="mt-10 mx-auto w-full max-w-screen-md mb-10">
        <p className="text-center my-6 text-2xl">Add Delivery </p>
        {status === "loading" ? (
          <LoadingSpinner />
        ) : (
          <>
            {deliveryResponse.slice(0, 4).map((delivery, index) => {
              return (
                <DeliveryCard
                  key={index}
                  name={delivery.name}
                  phone={delivery.phoneNumber}
                  address={delivery.address}
                  city={delivery.city}
                  onClick={() => dispatch(removeDeliveryAsync(delivery.id))}
                  rstatus={rstatus}
                />
              )
            })}
          </>
        )}

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-6 bg-white p-4 rounded-lg shadow-xl md:p-10 "
        >
          <PETextinput
            type={"text"}
            name={"address"}
            label={t("Address")}
            placeholder={t("Enter your address")}
            register={register}
            errors={errors}
          />

          <p>{t("City")}</p>
          <CountryDropdown />

          {/* <PETextinput
            type={"text"}
            name={"city"}
            label={t("City")}
            placeholder={t("Enter your city")}
            register={register}
            errors={errors}
          /> */}

          <PETextinput
            type={"text"}
            name={"name"}
            label={t("Full name")}
            placeholder={t("Enter your full name")}
            register={register}
            errors={errors}
          />

          <PETextinput
            type={"number"}
            name={"phoneNumber"}
            label={t("Phone number")}
            placeholder={t("Enter your phone number")}
            rules={{ required: t("Phone number is required") }}
            register={register}
            errors={errors}
          />

          {dstatus === "loading" ? (
            <LoadingSpinner />
          ) : (
            <PEButton name={t("Add address")} type="submit" />
          )}
        </form>
      </div>
    </Container>
  )
}

interface DeliveryCardProps {
  name: string
  phone: string
  address: string
  city: string
  onClick: () => void
  rstatus: string
}

const DeliveryCard = ({
  name,
  phone,
  address,
  city,
  onClick,
  rstatus,
}: DeliveryCardProps) => {
  const { t } = useTranslation()
  return (
    <div className=" w-full px-5 py-2.5 bg-white rounded-[10px] border border-neutral-500 flex-col justify-start items-center gap-[15px] inline-flex mb-10 divide-y-2">
      <div className="self-stretch justify-between items-center md:inline-flex ">
        <div className="font-bold text-neutral-600 text-[18px] md:font-normal font-['Inter']">
          {t("Full name")}
        </div>
        <div className="text-neutral-600 text-[18px] font-medium font-['Inter']">
          {name}
        </div>
      </div>
      <div className="self-stretch justify-between items-center md:inline-flex ">
        <div className="font-bold text-neutral-600 text-[18px] md:font-normal font-['Inter']">
          {t("Phone number")}
        </div>
        <div className="text-neutral-600 text-[18px] font-medium font-['Inter']">
          {phone}
        </div>
      </div>
      <div className="self-stretch justify-between items-center md:inline-flex ">
        <div className="font-bold text-neutral-600 text-[18px] md:font-normal font-['Inter']">
          {t("Address")}
        </div>
        <div className="text-neutral-600 text-[18px] font-medium font-['Inter']">
          {address}
        </div>
      </div>
      <div className="self-stretch justify-between items-center md:inline-flex ">
        <div className="font-bold text-neutral-600 text-[18px] md:font-normal font-['Inter']">
          {t("City")}
        </div>
        <div className="text-neutral-600 text-[18px] font-medium font-['Inter']">
          {city}
        </div>
      </div>
      <div>
        {rstatus === "loading" ? (
          <LoadingSpinner />
        ) : (
          <PEButton
            redBtn={true}
            name={t("Remove")}
            type="button"
            onClick={onClick}
          />
        )}
      </div>
    </div>
  )
}
