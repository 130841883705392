import { useForm, SubmitHandler } from "react-hook-form"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { loginAsync } from "./loginThunk"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"

const LoginSchema = z.object({
  phoneNumber: z.string().min(9, "Phone number must be at least 9 characters"),
  password: z.string().min(6, "Password must be atleast 6 characters"),
})

export interface ILoginForm {
  phoneNumber: string
  password: string
}

export const useLoginHook = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { status, userInfo: usrInfo } = useAppSelector((state) => state.login)

  const userInfo = localStorage.getItem("user")

  useEffect(() => {
    if (userInfo) {
      navigate("/")
    }

    if (usrInfo) {
      if ("userId" in usrInfo) {
        console.log(usrInfo.userId)
        navigate("/register/payment")
      }
    }
  }, [navigate, userInfo, usrInfo])

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginForm>({
    resolver: zodResolver(LoginSchema),
  })

  const onSubmit: SubmitHandler<ILoginForm> = (data: ILoginForm) => {
    dispatch(loginAsync(data))
  }

  return { register, handleSubmit, onSubmit, errors, status }
}
