import { useForm, SubmitHandler } from "react-hook-form"
import { useAppDispatch, useAppSelector } from "../../app/hooks"

import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { registerAsync } from "./registerThunk"
import useUploadImage from "../ImageUpload/useUploadImage"
import { removeImage } from "../ImageUpload/uploadImageSlice"
import { toast } from "react-toastify"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useTranslation } from "react-i18next"

export interface IRegisterForm {
  fullName: string
  phoneNumber: string
  country: string
  description: string
  password: string
  imageUrl?: any
}
export const useRegisterHook = () => {
  const { t } = useTranslation()
  const SignUpchema = z.object({
    password: z.string().min(6, t("Password must be atleast 6 characters")),
    fullName: z.string().min(6, t("Please fill the field")),
    phoneNumber: z.string().min(9).max(9),
    description: z.string().optional(),
    imageUrl: z.any().optional(),
  })

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isAgreed, setIsAgreed] = useState(false)
  const { userInfo, status, city } = useAppSelector((state) => state.register)

  const [image, setImage] = useState<File[]>([])

  const handleRemoveImage = (indexToRemove: number) => {
    setImage((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove),
    )
  }

  const {
    status: uploadImageStatus,
    uploadResult,
    error: uploadImageError,
    uploadImage,
    uploadMultipleImage,
    mstatus,
    muploadResult,
  } = useUploadImage()

  useEffect(() => {
    if (userInfo) {
      navigate("/register/payment")
      dispatch(removeImage())
    }
  }, [dispatch, navigate, userInfo])

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IRegisterForm>({
    resolver: zodResolver(SignUpchema),
  })

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files
    if (file) {
      uploadImage(file)
    }
  }

  const handleMultipleFile = (files: File[]) => {
    setImage(files)
    // if (files.length > 0) {
    //   uploadMultipleImage(files)
    // }
  }

  const uploadImg = () => {
    uploadMultipleImage(image)
  }

  const onSubmit: SubmitHandler<IRegisterForm> = (data: IRegisterForm) => {
    if (muploadResult) {
      data.imageUrl = muploadResult.filenames
    }

    if (!data.imageUrl) {
      return toast.error(
        t(
          "Please upload your ID documents or passport so we can verify your identity",
        ),
        {
          position: toast.POSITION.TOP_RIGHT,
        },
      )
    }

    if (!isAgreed) {
      return toast.error(t("Agree to our terms and conditions"), {
        position: toast.POSITION.TOP_RIGHT,
      })
    }

    if (!city) {
      return toast.error(t("Please choose your city"), {
        position: toast.POSITION.TOP_RIGHT,
      })
    }

    data.country = city

    dispatch(registerAsync(data))
  }

  return {
    register,
    handleSubmit,
    onSubmit,
    setIsAgreed,
    handleFileChange,
    handleMultipleFile,
    handleRemoveImage,
    uploadImg,
    errors,
    status,
    isAgreed,
    uploadImageStatus,
    uploadResult,
    uploadImageError,
    mstatus,
    image,
    muploadResult,
  }
}
