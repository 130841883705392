import { toast } from "react-toastify"
import { CheckoutResponse, ICreditResponse, Products } from "./checkoutApi"
import { createSlice } from "@reduxjs/toolkit"
import { addCheckoutAsync, getCheckoutAsync } from "./checkoutThunk"

interface CheckoutState {
  status: "idle" | "loading" | "rejected"
  mstatus: "idle" | "loading" | "rejected"
  ustatus: "idle" | "loading" | "rejected"
  error: string | undefined
  products: Products[]
  totalPrice: string | null
  deliveryAddressId: number | null
  checkoutResponse: null | CheckoutResponse
  ucheckoutResponse: ICreditResponse[]
}

const initialState: CheckoutState = {
  status: "idle",
  mstatus: "idle",
  ustatus: "loading",
  error: undefined,
  products: [],
  totalPrice: null,
  deliveryAddressId: null,
  checkoutResponse: null,
  ucheckoutResponse: [],
}

export const checkoutSlice = createSlice({
  name: "checkout",
  initialState,

  reducers: {
    addProducts: (state, action) => {
      state.products = action.payload
    },

    addTotalPrice: (state, action) => {
      state.totalPrice = action.payload
    },

    addDeliveryLocation: (state, action) => {
      state.deliveryAddressId = action.payload
    },

    resetCheckoutState: (state) => {
      state.status = "idle"
      state.mstatus = "idle"
      state.error = undefined
      state.products = []
      state.totalPrice = null
      state.deliveryAddressId = null
      state.checkoutResponse = null
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(addCheckoutAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(addCheckoutAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.checkoutResponse = action.payload

        toast.success(`Checkout success `, {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .addCase(addCheckoutAsync.rejected, (state, action) => {
        state.status = "rejected"
        state.error = action.error.message
        toast.error(`Error while checkout ${action.error.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .addCase(getCheckoutAsync.pending, (state) => {
        state.ustatus = "loading"
      })
      .addCase(getCheckoutAsync.fulfilled, (state, action) => {
        state.ustatus = "idle"
        state.ucheckoutResponse = action.payload
      })
      .addCase(getCheckoutAsync.rejected, (state, action) => {
        state.ustatus = "rejected"
        state.error = action.error.message
        toast.error(`Error while fetching checkout ${action.error.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  },
})
export const {
  addProducts,
  addTotalPrice,
  addDeliveryLocation,
  resetCheckoutState,
} = checkoutSlice.actions
export default checkoutSlice.reducer
