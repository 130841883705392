import { createAsyncThunk } from "@reduxjs/toolkit"
import {
  getProductApi,
  getProductByIdApi,
  searchProductApi,
} from "./productApi"

export const getProductsAsyc = createAsyncThunk(
  "products",
  async (page: number) => {
    try {
      const response = await getProductApi(page)
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  },
)

export const getProductByIdAsyc = createAsyncThunk(
  "products/id",
  async (id: number) => {
    try {
      const response = await getProductByIdApi(id)
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  },
)

export const searchProductAsyc = createAsyncThunk(
  "products/search",
  async (q: string) => {
    try {
      const response = await searchProductApi(q)
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  },
)
