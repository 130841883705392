import { useEffect, useState } from "react"
import MyModal from "../../components/Modal/SuccessModal"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import LoadingSpinner from "../../components/loading"
import { getCompleteImageUrl, getUser } from "../../utils/helpers"
import { PaymentResponse, getpaymentAsync } from "../Payment/paymentSlice"

import Dropdown from "../../components/Dropdown"
import { useNavigate } from "react-router-dom"
import useUploadImage from "../ImageUpload/useUploadImage"
import UploadImage from "../ImageUpload/UploadImage"
import { resetCheckoutState } from "./checkoutSlice"
import { toast } from "react-toastify"
import PEButton from "../../components/Input/PEPutton"
import { Checkout as ICheckout } from "./checkoutApi"
import { addCheckoutAsync } from "./checkoutThunk"
import { useTranslation } from "react-i18next"
import { removeAllFromCart, resetCartState } from "../Cart/cartSlice"

export default function Checkout() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const {
    status: uploadImageStatus,
    uploadResult,
    uploadImage,
  } = useUploadImage()

  const { paymentResponse, status } = useAppSelector((state) => state.payment)

  const {
    totalPrice,
    deliveryAddressId,
    status: checkoutstatus,
    checkoutResponse,
    products,
  } = useAppSelector((state) => state.checkout)
  const [selectedPayment, setSelectedPayment] =
    useState<PaymentResponse | null>(null)

  const [image, setImage] = useState<string | null>(null)
  const [img, setImg] = useState<FileList | null>(null)

  let [isOpen, setIsOpen] = useState(false)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files
    if (file) {
      const imageUrl = URL.createObjectURL(file[0])
      setImage(imageUrl)
      setImg(file)
    }
  }

  const uploadImg = () => {
    if (img) {
      uploadImage(img)
    }
  }

  useEffect(() => {
    dispatch(getpaymentAsync())
  }, [dispatch])

  useEffect(() => {
    if (checkoutResponse) {
      dispatch(removeAllFromCart())
      dispatch(resetCheckoutState())
      dispatch(resetCartState())

      navigate("/product")
    }
  }, [checkoutResponse, dispatch, navigate])

  useEffect(() => {
    if (!totalPrice || products.length === 0) {
      navigate("/cart")
    }
  }, [checkoutResponse, navigate, products, totalPrice])

  const handleSubmit = () => {
    const user = getUser()

    if (!deliveryAddressId) {
      return toast.error(`${t("Please select your delivery location")}`, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }

    if (!selectedPayment) {
      return toast.error(`${t("Please select your payment method")}`, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }

    const data: ICheckout = {
      totalPrice: totalPrice ?? "",
      paymentId: selectedPayment?.name ?? "",
      paymentImageUrl: uploadResult?.filename ?? "",
      deliveryAddressId: deliveryAddressId ?? 0,
      products: products ?? [],
      userId: user.id,
    }

    if (!uploadResult) {
      return toast.error(`${t("Please upload payment proof image")}`, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }

    dispatch(addCheckoutAsync(data))
  }

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }
  return (
    <div className=" container mt-10 flex flex-col items-center justify-center mx-auto max-w-7xl px-2 md:px-8">
      <MyModal isOpen={isOpen} closeModal={closeModal} openModal={openModal} />

      <div className="text-center text-neutral-800 text-2xl font-semibold font-['Inter']">
        {t("Choose Payment Method")}
      </div>

      <div className="mt-10 mx-auto w-full max-w-screen-md ">
        <div className="p-4 rounded-lg border-2 border-graybg mb-6 flex justify-between">
          <p className="text-justify ">{t("Subtotal")}</p>
          <p className="text-justify font-bold">{totalPrice} ETB</p>
        </div>

        <Dropdown />

        <div className="space-y-4 bg-white p-4 rounded-lg shadow-md">
          {status === "loading" ? (
            <LoadingSpinner />
          ) : (
            <>
              {paymentResponse?.map((payment, index) => {
                return (
                  <div
                    key={index}
                    className={`flex p-2 rounded-lg border-2 border-neutral-300 mb-6 items-center justify-between hover:bg-primary hover:text-white ${
                      selectedPayment?.id === payment.id
                        ? "bg-primary text-white"
                        : ""
                    }`}
                    onClick={() => {
                      setSelectedPayment(payment)
                    }}
                  >
                    <img
                      className="h-12 w-12 rounded-full"
                      src={getCompleteImageUrl(payment.imageUrl)}
                      alt={payment.name}
                    />
                    <p>{payment.name}</p>
                    <p>{payment.accountNumber}</p>
                  </div>
                )
              })}
            </>
          )}
        </div>

        <div className=" my-4 text-center text-neutral-800">
          {t("Send a screenshot of your transfer for payment proof.")}
        </div>

        {image && (
          <div className="relative w-40">
            <img className="h-40  object-contain" alt="selected" src={image} />
            <button
              className="absolute top-0 right-0 text-red-500 text-xl text-center rounded-full bg-white h-6 w-6 flex items-center justify-center"
              onClick={() => setImage(null)}
            >
              X
            </button>
          </div>
        )}

        {uploadImageStatus === "loading" ? (
          <LoadingSpinner />
        ) : (
          <>
            {image ? (
              <PEButton
                name={"Upload image"}
                type={"button"}
                onClick={uploadImg}
              />
            ) : (
              <UploadImage onChange={handleFileChange} />
            )}
          </>
        )}
        <div className="mt-6">
          {checkoutstatus === "loading" ? (
            <LoadingSpinner />
          ) : (
            <PEButton
              disabled={!uploadResult}
              name={t("Finish payment")}
              type="button"
              onClick={() => {
                handleSubmit()
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}
