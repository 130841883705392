import Container from "../../components/Container"
import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import LoadingSpinner from "../../components/loading"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import PDFViewer from "../../components/PdfReader"

import PEButton from "../../components/Input/PEPutton"
import { getTermsAndConditionAsyc } from "./termsAndConditionThunk"
import PDFReader from "../../components/ReactPDFReader"
import { getCompleteImageUrl } from "../../utils/helpers"

const TermsAndCondition = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { termsAndConditions, status } = useAppSelector(
    (state) => state.termsAndCondition,
  )

  useEffect(() => {
    dispatch(getTermsAndConditionAsyc())
  }, [dispatch])

  if (status === "loading") {
    return <LoadingSpinner />
  }

  if (termsAndConditions.length === 0) {
    return (
      <p className="text-center p-10 text-2xl">
        Terms And Conditions not found
      </p>
    )
  }

  return (
    <Container>
      <div className="text-center text-2xl  py-6">Terms And Condition</div>

      <div className="my-10 mx-2">
        <div className=" md:hidden">
          <PDFReader url={getCompleteImageUrl(termsAndConditions[0].pdfUrl)} />
        </div>
        <div className="hidden md:block">
          <PDFViewer pdfUrl={termsAndConditions[0].pdfUrl} />
        </div>
      </div>

      <PEButton
        name={t("Home")}
        type={"button"}
        onClick={() => navigate("/")}
      />
    </Container>
  )
}

export default TermsAndCondition
