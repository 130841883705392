import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { getCheckoutAsync } from "./checkoutThunk"

export const useCheckout = () => {
  const dispatch = useAppDispatch()
  const [page, setPage] = useState(1)
  const { ucheckoutResponse, ustatus } = useAppSelector(
    (state) => state.checkout,
  )

  useEffect(() => {
    dispatch(getCheckoutAsync(page))
  }, [dispatch, page])

  return { ucheckoutResponse, ustatus, page, setPage }
}
