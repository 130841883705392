import Container from "../../components/Container"
import { useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { useEffect, useState } from "react"
import { getCourseByTrainingIdAsync } from "./courseThunk"
import LoadingSpinner from "../../components/loading"
import PDFViewer from "../../components/PdfReader"
import { Pagination } from "../../components/Pagination"
import { getCompleteImageUrl } from "../../utils/helpers"
import PDFReader from "../../components/ReactPDFReader"
const Course = () => {
  const dispatch = useAppDispatch()
  const { id } = useParams()

  const { courses, status } = useAppSelector((state) => state.course)

  const [courseIndex, setCourseIndex] = useState(0)

  useEffect(() => {
    dispatch(getCourseByTrainingIdAsync(Number(id)))
  }, [dispatch, id])

  useEffect(() => {}, [courseIndex])

  if (status === "loading") {
    return <LoadingSpinner />
  }

  if (courses.length === 0) {
    return <p className="text-center p-10 text-2xl">Course not found</p>
  }

  return (
    <Container>
      <p>
        {courseIndex + 1} / {courses.length}{" "}
      </p>
      <section>
        <div className="text-center my-4">
          <h1 className="text-2xl">{courses[courseIndex].title}</h1>
        </div>
      </section>
      {courses[courseIndex].videoUrl && (
        <div className="relative w-full ">
          <iframe
            title={courses[courseIndex].title}
            src={`https://drive.google.com/file/d/${courses[courseIndex].videoUrl}/preview?`}
            className="w-full h-[320px] md:h-[720px] "
            allow="autoplay"
            allowFullScreen
          ></iframe>

          <div className="absolute right-3  top-3  w-10 h-10 bg-white rounded-sm">
            <img src="/logo.png" alt="powerethio logo" />
          </div>
        </div>
      )}
      <div className="mt-10 p-6  bg-graybg rounded-md">
        <p className=" text-justify text-white text-xl">
          {courses[courseIndex].subTitle}
        </p>
      </div>
      <div className="my-10 mx-2">
        <div className=" md:hidden">
          <PDFReader url={getCompleteImageUrl(courses[courseIndex].pdfUrl)} />
        </div>
        <div className="hidden md:block">
          <PDFViewer pdfUrl={courses[courseIndex].pdfUrl} />
        </div>
      </div>

      <section className="flex w-full justify-end">
        <Pagination
          previous={courseIndex > 0}
          next={courses.length - 1 !== courseIndex}
          loadPreviousPage={() => setCourseIndex((prev) => prev - 1)}
          loadNextPage={() => setCourseIndex((prev) => prev + 1)}
        />
      </section>
    </Container>
  )
}

export default Course
