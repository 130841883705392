import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getToken, getUser } from "../../utils/helpers"
import { axiosInstance } from "../../app/service/api"

export interface NotificationResponse {
  id: number
  title: string
  description: string
  productId: string
  trainingId: string
  isSeen: boolean
  userId: number
  createdAt: string
  updatedAt: string
}

interface NotificationState {
  status: "idle" | "loading" | "rejected"
  error: string | undefined
  notificationReponse: NotificationResponse[]
}

const initialState: NotificationState = {
  status: "idle",
  error: undefined,
  notificationReponse: [],
}

export const getNotificationApi = async (page: number) => {
  const token = getToken()
  const user = getUser()
  try {
    const response = await axiosInstance.get<NotificationResponse[]>(
      `notification/user/${user.id}?page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getNotificationAsync = createAsyncThunk(
  "notification",
  async (page: number) => {
    try {
      const response = getNotificationApi(page)
      return response
    } catch (error) {
      throw error
    }
  },
)

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getNotificationAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getNotificationAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.notificationReponse = action.payload
      })
      .addCase(getNotificationAsync.rejected, (state, action) => {
        state.error = action.error.message
      })
  },
})

export default notificationSlice.reducer
