// i18n.js
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import en from "./locales/en.json"
import or from "./locales/or.json"
import am from "./locales/am.json"
import tr from "./locales/tr.json"

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translations: en,
    },
    am: {
      translations: am,
    },
    or: {
      translations: or,
    },
    tr: {
      translations: tr,
    },
  },
  lng: "en", // default language
  fallbackLng: "en", // fallback language
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  ns: ["translations"],
  defaultNS: "translations",
})

export default i18n
