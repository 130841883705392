import { setLanguage } from "./slices/localizationSlice"
import { useAppDispatch } from "../../app/hooks"
import { Fragment, useEffect, useState } from "react"
import { Listbox, Transition } from "@headlessui/react"
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid"

const language = [
  { name: "English", value: "en" },
  { name: "አማርኛ", value: "am" },
  { name: "Afan Oromo", value: "or" },
  { name: " ትግርኛ", value: "tr" },
  { name: "Afar", value: "ar" },
  { name: "Wolayttatto Doonaa", value: "wo" },
  { name: "Somali", value: "so" },
]

interface LanguageProps {
  fromProfile: boolean
}

export default function LanguageSwitcher({ fromProfile }: LanguageProps) {
  const dispatch = useAppDispatch()

  const lang = localStorage.getItem("language") ?? "en"

  const handleLanguageChange = (language: string) => {
    dispatch(setLanguage(language))
  }

  const [selected, setSelected] = useState(language[0])

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    language.map((lg) => {
      if (lg.value === lang) setSelected(lg)
    })
  }, [lang])

  return (
    <div className={`top-16 ${fromProfile ? "w-full" : "w-72"} `}>
      <Listbox
        value={selected}
        onChange={(e) => {
          setSelected(e)
          handleLanguageChange(e.value)
        }}
      >
        <div className="relative mt-1 ">
          <Listbox.Button
            className={`relative w-full cursor-default rounded-lg hover:cursor-pointer ${
              fromProfile
                ? "bg-primary text-white text-center py-3"
                : "bg-white"
            }  py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-primary-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm `}
          >
            <span className="block truncate">{selected.name}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {language.map((person, personIdx) => (
                <Listbox.Option
                  key={personIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? "bg-white text-primary" : "text-gray-900"
                    }`
                  }
                  value={person}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate hover:cursor-pointer ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {person.name}
                      </span>
                      {person.value === lang ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-secondary">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}
