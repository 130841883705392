import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import {
  TrainingCategory,
  fetchTrainingCategoryApi,
} from "./trainingCategorySliceApi"

export interface ImageSlideState {
  trainingCategory: TrainingCategory[]
  status: "idle" | "loading" | "rejected"
}

const initialState: ImageSlideState = {
  trainingCategory: [],
  status: "idle",
}

export const getTrainingCategoryAsync = createAsyncThunk(
  "training/category",
  async () => {
    const response = await fetchTrainingCategoryApi()
    return response
  },
)

export const trainingCategorySlice = createSlice({
  name: "trainingCategory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTrainingCategoryAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getTrainingCategoryAsync.fulfilled, (state, action) => {
        state.status = "idle"

        state.trainingCategory = action.payload
      })
      .addCase(getTrainingCategoryAsync.rejected, (state) => {
        state.status = "rejected"
      })
  },
})

export default trainingCategorySlice.reducer
