import { useLocation, useNavigate, useParams } from "react-router-dom"
import Container from "../../components/Container"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { getCompleteImageUrl } from "../../utils/helpers"
import { useEffect, useState } from "react"
import { getTrainingByCategoryIdAsync } from "./trainingThunk"
import LoadingSpinner from "../../components/loading"
import { Pagination } from "../../components/Pagination"
import { useTranslation } from "react-i18next"

const Training = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { id } = useParams()

  const location = useLocation()
  const receivedData = location.state

  const [nextPage, setNextPage] = useState(1)

  const { trainings, status } = useAppSelector((state) => state.trainings)

  useEffect(() => {
    dispatch(getTrainingByCategoryIdAsync({ id: Number(id), page: nextPage }))
  }, [dispatch, id, nextPage])

  if (status === "loading") {
    return <LoadingSpinner />
  }

  if (trainings.length === 0) {
    return <p className="text-center p-10 text-2xl">{t("Course not found")}</p>
  }

  return (
    <Container>
      <section>
        <div className="text-center mt-6">
          <h1 className="text-2xl">{receivedData}</h1>
        </div>
      </section>

      <>
        {trainings.map((training) => {
          return (
            <div
              key={training.createdAt}
              className="md:flex shadow-md p-4  rounded-lg bg-white mt-6 w-full"
            >
              <img
                src={getCompleteImageUrl(training.imageUrl)}
                alt={training.title}
                className="object-cover h-36 w-full md:w-44 rounded-lg"
              />

              <div className=" mt-4 md:ml-4">
                <h2 className="font-bold text-xl">{training.title}</h2>
                <p className="line-clamp-3 text-ellipsis text-justify">
                  {training.description}
                </p>
                <div className="flex justify-between items-center mt-4">
                  {/* <p className="">20 Videos | 4 pdfs</p> */}
                  <button
                    className="px-5 py-2 bg-primary rounded-[10px] justify-center items-center inline-flex text-white"
                    onClick={() => navigate(`/course/${training.id}`)}
                  >
                    {t("Take training")}
                  </button>
                </div>
              </div>
            </div>
          )
        })}
      </>

      <section className="flex w-full justify-end">
        <Pagination
          previous={nextPage > 1}
          next={trainings.length > 9}
          loadPreviousPage={() => setNextPage((prev) => prev - 1)}
          loadNextPage={() => setNextPage((prev) => prev + 1)}
        />
      </section>
    </Container>
  )
}

export default Training
