import { createSlice } from "@reduxjs/toolkit"
import { loginAsync, logoutAsync } from "./loginThunk"
import { LoginProcessResponse, LoginResponse } from "./loginAPI"

interface LoginState {
  status: "idle" | "loading" | "rejected"
  error: string | undefined
  isLoggedIn: boolean
  userInfo: null | LoginResponse | LoginProcessResponse
  token: string | null
}

const initialState: LoginState = {
  status: "idle",
  error: undefined,
  isLoggedIn: false,
  userInfo: null,
  token: null,
}

export const loginSlice = createSlice({
  name: "login",
  initialState,

  reducers: {
    resetLoginState: (state) => {
      state.status = "idle"
      state.error = undefined
      state.isLoggedIn = false
      state.userInfo = null
      state.token = null
    },

    logout: (state) => {
      localStorage.removeItem("user")
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.status = "idle"

        state.isLoggedIn = true
        state.userInfo = action.payload

        if ("token" in action.payload) {
          state.token = action.payload.token
          localStorage.setItem("user", JSON.stringify(action.payload))
        }
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.status = "rejected"
        state.error = action.error.message
      })
      .addCase(logoutAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(logoutAsync.fulfilled, (state, action) => {
        state.error = undefined
        state.isLoggedIn = false
        state.token = null
        state.userInfo = null

        localStorage.removeItem("user")
        state.status = "idle"
      })
      .addCase(logoutAsync.rejected, (state, action) => {
        state.status = "rejected"
        state.error = action.error.message
      })
  },
})

export const { resetLoginState, logout } = loginSlice.actions

export default loginSlice.reducer
