import Container from "../../components/Container"
import { useEffect, useState } from "react"
import { getAboutOnBoardingAsyc } from "./aboutOnBoardingThunk"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import LoadingSpinner from "../../components/loading"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import PDFReader from "../../components/ReactPDFReader"
import { getCompleteImageUrl } from "../../utils/helpers"
import PDFViewer from "../../components/PdfReader"

const AboutOnBoarding = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [next, setNext] = useState(0)

  const { aboutOnBoarding, status } = useAppSelector(
    (state) => state.aboutOnBoarding,
  )

  useEffect(() => {
    dispatch(getAboutOnBoardingAsyc())
  }, [dispatch])

  if (status === "loading") {
    return (
      <div className="flex h-screen items-center justify-center">
        <LoadingSpinner />
      </div>
    )
  }

  if (aboutOnBoarding.length === 0) {
    return (
      <p className="text-center p-10 text-2xl">About on boarding not found</p>
    )
  }
  return (
    <Container>
      <div className="flex space-x-2 mt-10">
        {next > 0 && (
          <button
            className="px-4 py-3 bg-primary text-white rounded-md"
            onClick={() => setNext((prev) => prev - 1)}
          >
            {t("Previous")}
          </button>
        )}

        <button
          className="px-4 py-3 bg-primary text-white rounded-md"
          onClick={() => {
            if (next === aboutOnBoarding.length - 1) {
              navigate("/register")
            }
            setNext((prev) => prev + 1)
          }}
        >
          {t("Next")}
        </button>
      </div>
      <div className="px-24 py-4 text-center text-2xl border-gray-500 border-2 rounded-lg my-10">
        {aboutOnBoarding[next].title}
      </div>

      <div className="relative w-full ">
        <iframe
          title={aboutOnBoarding[next].title}
          src={`https://drive.google.com/file/d/${aboutOnBoarding[next].videoUrl}/preview?showinfo=0`}
          className="w-full h-[320px] md:h-[720px] "
          allow="autoplay"
          allowFullScreen
        ></iframe>

        <div className="absolute right-3  top-3  w-10 h-10 bg-white rounded-sm">
          <img src="/logo.png" alt="powerethio logo" />
        </div>
      </div>

      <div className="mt-10 p-6  bg-graybg rounded-md">
        <p className=" text-justify text-white text-xl">
          {aboutOnBoarding[next].subTitle}
        </p>
      </div>
      <div className="my-10 mx-2">
        <div className=" md:hidden">
          <PDFReader url={getCompleteImageUrl(aboutOnBoarding[next].pdfUrl)} />
        </div>
        <div className="hidden md:block">
          <PDFViewer pdfUrl={aboutOnBoarding[next].pdfUrl} />
        </div>
      </div>
    </Container>
  )
}

export default AboutOnBoarding
