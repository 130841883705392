import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { axiosInstance } from "../../app/service/api"

interface Country {
  id: number
  name: string
  updatedAt: string
  createdAt: string
}

export const getCountryApi = async () => {
  try {
    const response = await axiosInstance.get<Country[]>("country")

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getCountryAsyc = createAsyncThunk("country", async () => {
  try {
    const response = await getCountryApi()
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
})

interface CountryState {
  status: "idle" | "loading" | "rejected"
  error: string | undefined
  country: Country[]
}

const initialState: CountryState = {
  status: "loading",
  error: undefined,
  country: [],
}

const countrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getCountryAsyc.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getCountryAsyc.fulfilled, (state, action) => {
        state.status = "idle"
        state.country = action.payload
      })
      .addCase(getCountryAsyc.rejected, (state, action) => {
        state.status = "rejected"
        state.error = action.error.message
      })
  },
})

export default countrySlice.reducer
