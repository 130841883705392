import React from "react"
import { useTranslation } from "react-i18next"

interface UploadImageProps {
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined
}

const UploadImage = ({ onChange }: UploadImageProps) => {
  const { t } = useTranslation()
  return (
    <div>
      <label htmlFor="image-upload" className="cursor-pointer">
        <div className="w-full py-3 rounded-md overflow-hidden border-2 bg-primary">
          <div className="text-center">
            <span className="text-white">{t("Add Image")}</span>
          </div>
        </div>
      </label>

      <input
        id="image-upload"
        type={"file"}
        accept={"image/*"}
        className="hidden"
        onChange={onChange}
      />
    </div>
  )
}

export default UploadImage
