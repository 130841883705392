import { baseUrl } from "./constants"

export const getToken = () => {
  let user = localStorage.getItem("user")
  if (!user) return
  let userJson = JSON.parse(user)
  const token = userJson.token

  return token
}

export const getUser = () => {
  let user = localStorage.getItem("user")
  if (!user) return
  let userJson = JSON.parse(user)

  return userJson
}

export const getCompleteImageUrl = (imageUrl: string) => {
  return `${baseUrl}/uploads/${imageUrl}`
}
