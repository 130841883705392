import { useNavigate } from "react-router-dom"
import PETextinput from "../../components/Input/Input"
import PEButton from "../../components/Input/PEPutton"
import LoadingSpinner from "../../components/loading"
import { useLoginHook } from "./useLoginHook"
import { useTranslation } from "react-i18next"

export default function Login() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { handleSubmit, onSubmit, register, errors, status } = useLoginHook()

  return (
    <div className="mt-10  mx-auto w-full max-w-screen-sm flex flex-col h-screen justify-center ">
      <div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-6 bg-white p-4 rounded-lg shadow-xl md:p-10 "
        >
          <div className="flex h-full justify-center items-center">
            <div className="h-36 w-36 rounded-[100px] bg-secondary p-4">
              <img src="/logo.png" alt="power ethio" />
            </div>
          </div>
          <h1 className="text-center text-4xl font-thin">Power Ethio</h1>

          <PETextinput
            type={"number"}
            name={"phoneNumber"}
            label={t("Phone number")}
            placeholder={t("Enter your phone number")}
            rules={{ required: t("Phone number is required") }}
            register={register}
            errors={errors}
          />

          <PETextinput
            type={"password"}
            name={"password"}
            label={t("Password")}
            placeholder={t("Enter your password")}
            rules={{ required: t("Password is required") }}
            register={register}
            errors={errors}
          />

          {status === "loading" ? (
            <LoadingSpinner />
          ) : (
            <PEButton name={t("Login")} type="submit" />
          )}
        </form>

        <div className="mt-10 text-center text-neutral-800 text-sm font-medium ">
          {t("Don’t have an account?")}
        </div>

        <button
          onClick={() => navigate("/language")}
          className="mt-6  flex w-full justify-center rounded-md bg-yellow px-3 py-4 text-sm font-semibold leading-6 text-primary shadow-sm hover:bg-yellow focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow"
        >
          {t("Register")}
        </button>
      </div>
    </div>
  )
}
