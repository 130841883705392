import { Menu, Transition } from "@headlessui/react"
import { Fragment, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { getCountryAsyc } from "./countrySlice"
import LoadingSpinner from "../../components/loading"
import { addCity } from "../register/registerSlice"
import { addCity as dAddCity } from "../Delivery/deliverySlice"

export default function CountryDropdown() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [selectedDelivery, setSelectedDelivery] = useState(t("Choose city"))
  const [searchQuery, setSearchQuery] = useState("")

  const { country, status } = useAppSelector((state) => state.country)

  useEffect(() => {
    dispatch(getCountryAsyc())
  }, [dispatch])

  useEffect(() => {
    if (selectedDelivery !== t("Choose city")) {
      dispatch(addCity(selectedDelivery))
      dispatch(dAddCity(selectedDelivery))
    }
  }, [dispatch, selectedDelivery, t])

  const filteredCountries = country.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase()),
  )

  if (status === "loading") {
    return <LoadingSpinner />
  }

  return (
    <div className="my-4">
      <Menu as="div" className="relative inline-block text-left w-full">
        <div>
          <Menu.Button className="inline-flex justify-center w-full px-4 py-4 text-sm font-medium bg-white rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 border-2">
            {selectedDelivery === "Delivery Location"
              ? t(selectedDelivery)
              : selectedDelivery}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className="w-full absolute left-0 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            style={{ maxHeight: "200px", overflowY: "auto" }} // Set a maximum height and enable vertical scrolling
          >
            <div className="px-2 py-2">
              <input
                type="text"
                placeholder={t("Search city")}
                className="w-full px-2 py-1 border rounded-md"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            {filteredCountries.map((item, index) => (
              <div key={index} className="px-1 py-1">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? "bg-primary text-white" : "text-gray-900"
                      } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                      onClick={() => setSelectedDelivery(item.name)}
                    >
                      {item.name}
                    </button>
                  )}
                </Menu.Item>
              </div>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}
