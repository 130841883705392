import { Link, useLocation } from "react-router-dom"
import { ICreditResponse } from "./checkoutApi"
import { getCompleteImageUrl } from "../../utils/helpers"
import { useTranslation } from "react-i18next"
import moment from "moment"

const OrdersDetail = () => {
  const { t } = useTranslation()

  let { state }: { state: ICreditResponse } = useLocation()

  const date = moment(state.createdAt).fromNow()

  return (
    <div className="mt-10 mx-auto w-full max-w-screen-md mb-10 px-4">
      <div className="space-y-6">
        <p className="font-bold text-xl text-start">{t("Order Detail")}</p>
        <div className="gap-10 bg-white shadow-md  px-4 py-6 rounded-lg space-y-4">
          <p>
            <strong>{t("Total price")}: </strong>
            {state.totalPrice}
          </p>
          <p>
            <strong>{t("Date")}: </strong>
            <span className="text-sm font-light my-2">{date}</span>
          </p>
          <p>
            <strong>{t("Status")}: </strong>
            <span className="border-2 rounded-full p-2 font-semibold text-primary">
              {state.status}
            </span>
          </p>
        </div>

        <p className="font-bold text-xl text-start mb-2">
          {t("Delivery Address")}
        </p>
        <div className="bg-white px-4 py-6 rounded-lg shadow-md">
          <p>
            <strong>{t("Name")}: </strong>
            {state.deliveryAddress.name}
          </p>
          <p>
            <strong>{t("City")}: </strong>
            {state.deliveryAddress.city}
          </p>
          <p>
            <strong>{t("Address")}: </strong>
            {state.deliveryAddress.address}
          </p>
          <p>
            <a href={`tel:${state.deliveryAddress.phoneNumber}`}>
              <strong>{t("Phone number")}: </strong>
              {state.deliveryAddress.phoneNumber}
            </a>
          </p>
        </div>

        <p className="font-bold text-xl space-y-8">{t("Products")}</p>
        {state.Products.map((product, index) => {
          return (
            <div
              className="  bg-white rounded-lg px-4 py-6 shadow-md"
              key={index}
            >
              <Link to={`/product/${product.id}`} className="flex gap-4 ">
                <img
                  src={getCompleteImageUrl(product.imageUrl.split(",")[0])}
                  alt={"order"}
                  className="w-20 h-20 object-cover rounded-full"
                />
                <div>
                  <p>
                    <strong>{t("Name")}: </strong>
                    {product.name}
                  </p>
                  <p>
                    <strong>{t("Price")}: </strong> {product.price}
                  </p>
                  <p>
                    <strong>{t("Quantity")}: </strong>{" "}
                    {product.ProductCheckout.quantity}
                  </p>
                </div>
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default OrdersDetail
