import { createAsyncThunk } from "@reduxjs/toolkit"
import { loginApi, logoutApi } from "./loginAPI"
import { ILoginForm } from "./useLoginHook"

export const loginAsync = createAsyncThunk(
  "login",
  async (formData: ILoginForm) => {
    try {
      const response = await loginApi(formData)
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  },
)

export const logoutAsync = createAsyncThunk("logout", async () => {
  try {
    const response = await logoutApi()
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
})
