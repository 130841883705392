import { Fragment, useEffect, useState } from "react"
import { Disclosure, Menu, Transition } from "@headlessui/react"
import {
  ArrowLeftEndOnRectangleIcon,
  Bars3Icon,
  MagnifyingGlassIcon,
  ShoppingCartIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline"
import { UserCircleIcon } from "@heroicons/react/24/outline"
import { Outlet, useNavigate, useLocation } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import Footer from "./Footer"
import { searchProductAsyc } from "../features/Product/productThunk"
import { useDebounce } from "use-debounce"
import { useTranslation } from "react-i18next"
import { logoutAsync } from "../features/login/loginThunk"
import LoadingSpinner from "./loading"

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}

export default function Navbar() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { pathname } = location

  const { products: cart } = useAppSelector((state) => state.cart)
  const { status: loginStatus } = useAppSelector((state) => state.login)

  const [current, setCurrent] = useState("Home")

  const [searchQuery, setSearchQuery] = useState("")
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000)

  useEffect(() => {
    dispatch(searchProductAsyc(debouncedSearchQuery))
  }, [debouncedSearchQuery, dispatch])

  const navigation = [
    { name: t("Home"), href: "/" },
    { name: t("Shop"), href: "/product" },
    { name: t("Credit"), href: "/credit" },
    { name: t("Order"), href: "/orders" },
  ]
  return (
    <>
      <Disclosure as="nav">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-primary hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="hidden md:block  flex-shrink-0 items-center justify-center">
                  <img
                    className="h-14 w-auto"
                    src="/logo.png"
                    alt="Power Ethio"
                    onClick={() => navigate("/")}
                  />
                </div>

                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="md:hidden flex flex-shrink-0 items-center justify-center">
                    <img
                      className="h-14 w-auto"
                      src="/logo.png"
                      alt="Power Ethio"
                      onClick={() => navigate("/")}
                    />
                  </div>
                  <div className="hidden sm:ml-6 sm:block">
                    <div className="flex space-x-4 items-center">
                      {navigation.map((item) => {
                        return (
                          <button
                            key={item.name}
                            onClick={() => {
                              navigate(item.href)
                              setCurrent(item.name)
                            }}
                            className={classNames(
                              item.href === pathname
                                ? "bg-primary text-white"
                                : "text-gray-600 hover:bg-primary hover:bg-opacity-80 hover:text-white",
                              "rounded-md px-3 py-2 text-sm font-medium",
                            )}
                          >
                            {item.name}
                          </button>
                        )
                      })}
                      {pathname === "/product" && (
                        <div className="flex  items-center px-4  rounded-full border-1 ring-1 border-primary">
                          <MagnifyingGlassIcon className="h-8 w-8 text-primary mr-4" />
                          <input
                            placeholder={t("Search")}
                            className=" outline-none bg-transparent rounded-full  py-2"
                            onChange={(e) => setSearchQuery(e.target.value)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 space-x-2">
                  <button onClick={() => navigate("/notification")}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-8 h-8 hover:text-primary"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0"
                      />
                    </svg>
                  </button>

                  <button onClick={() => navigate("/cart")}>
                    <div className="relative">
                      <ShoppingCartIcon
                        className={`h-8 w-8 hover:text-primary`}
                      />
                      {cart.length > 0 && (
                        <div className="absolute bg-red-500 h-3 w-3 rounded-full top-0 right-0 text-white text-center"></div>
                      )}
                    </div>
                  </button>
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="relative flex rounded-full  text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800 hover:text-primary">
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Open user menu</span>

                        <UserCircleIcon className="h-8 w-8 rounded-full " />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-4 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <div
                              className={`flex ${classNames(
                                active ? "bg-gray-100 text-primary" : "",
                                "block px-4 py-2 text-sm text-gray-700",
                              )}`}
                              onClick={() => navigate("/profile")}
                            >
                              <UserCircleIcon className={`h-10 w-10`} />

                              <button className="block px-4 py-2 text-sm text-gray-700">
                                {t("Your Profile")}
                              </button>
                            </div>
                          )}
                        </Menu.Item>

                        <Menu.Item>
                          {({ active }) => (
                            <>
                              {loginStatus === "loading" ? (
                                <LoadingSpinner />
                              ) : (
                                <div
                                  className={`flex ${classNames(
                                    active ? "bg-gray-100 text-primary" : "",
                                    "block px-4 py-2 text-sm text-gray-700",
                                  )}`}
                                  onClick={async () => {
                                    await dispatch(logoutAsync())
                                    navigate("/login")
                                  }}
                                >
                                  <ArrowLeftEndOnRectangleIcon
                                    className={`h-10 w-10`}
                                  />

                                  <button className="block px-4 py-2 text-sm text-gray-700">
                                    {t("Logout")}
                                  </button>
                                </div>
                              )}
                            </>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden bg-gray-50">
              <div className="space-y-1 px-2 pb-3 pt-2">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    onClick={() => {
                      navigate(item.href)
                      setCurrent(item.name)
                    }}
                    className={classNames(
                      item.href === pathname
                        ? "bg-primary text-white"
                        : "text-gray-600 hover:bg-gray-700 hover:text-white",
                      "block rounded-md px-3 py-2 text-base font-medium",
                    )}
                    aria-current={item.name === current ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
                {pathname === "/product" && (
                  <div className="flex items-center px-4  rounded-full border-1 ring-1 border-primary">
                    <MagnifyingGlassIcon className="h-8 w-8 text-primary mr-4" />
                    <input
                      placeholder={t("Search")}
                      className=" outline-none bg-transparent rounded-full  py-2"
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                )}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <div className="min-h-screen">
        <Outlet />
      </div>

      <Footer />
    </>
  )
}
