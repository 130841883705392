import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { getCompleteImageUrl } from "../../utils/helpers"
import UploadImage from "../ImageUpload/UploadImage"
import { PaymentResponse, getpaymentAsync } from "../Payment/paymentSlice"
import LoadingSpinner from "../../components/loading"
import useUploadImage from "../ImageUpload/useUploadImage"
import {
  registrationPaymentAsync,
  resetRegistrationPayment,
} from "./registartionPaymentSlice"
import { useNavigate } from "react-router-dom"
import PEButton from "../../components/Input/PEPutton"
import { toast } from "react-toastify"
import { resetRegisterState } from "../register/registerSlice"
import { useTranslation } from "react-i18next"
import { resetLoginState } from "../login/loginSlice"

export default function RegistrationPayment() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { paymentResponse, status } = useAppSelector((state) => state.payment)
  const { status: rpstatus, isSucced } = useAppSelector(
    (state) => state.registrationPayment,
  )
  const { userInfo } = useAppSelector((state) => state.register)
  const { userInfo: loginUserInfo } = useAppSelector((state) => state.login)

  const [image, setImage] = useState<string | null>(null)
  const [img, setImg] = useState<FileList | null>(null)

  const [selectedPayment, setSelectedPayment] =
    useState<PaymentResponse | null>(null)

  const {
    status: uploadImageStatus,
    uploadResult,
    // error: uploadImageError,
    uploadImage,
  } = useUploadImage()

  useEffect(() => {
    dispatch(getpaymentAsync())
  }, [dispatch])

  useEffect(() => {
    if (isSucced) {
      navigate("/login")
      dispatch(resetRegisterState())
      dispatch(resetRegistrationPayment())
      dispatch(resetLoginState())
    }
  }, [dispatch, isSucced, navigate])

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files
    if (file) {
      const imageUrl = URL.createObjectURL(file[0])
      setImage(imageUrl)
      setImg(file)
    }
  }

  const uploadImg = () => {
    if (img) {
      uploadImage(img)
    }
  }

  return (
    <div className="container mt-10 flex flex-col items-center justify-center mx-auto max-w-7xl px-2 md:px-8">
      <div className="text-center text-neutral-800 text-2xl font-semibold font-['Inter']">
        {t("Choose Payment Method")}
      </div>

      <div className="mt-10 mx-auto w-full max-w-screen-md ">
        <div className="space-y-4 bg-white p-4 rounded-lg shadow-md">
          {status === "loading" ? (
            <LoadingSpinner />
          ) : (
            <>
              {paymentResponse?.map((payment) => {
                return (
                  <div
                    key={payment.id}
                    className={`flex p-2 rounded-lg border-2 border-neutral-300 mb-6 items-center justify-between hover:bg-primary hover:text-white ${
                      selectedPayment?.id === payment.id
                        ? "bg-primary text-white"
                        : ""
                    }`}
                    onClick={() => {
                      setSelectedPayment(payment)
                    }}
                  >
                    <img
                      className="h-12 w-12 rounded-full"
                      src={getCompleteImageUrl(payment.imageUrl)}
                      alt={payment.name}
                    />
                    <p>{payment.name}</p>
                    <p>{payment.accountNumber}</p>
                  </div>
                )
              })}
            </>
          )}

          <div className=" my-4 text-center text-neutral-800">
            {t("Send a screenshot of your transfer for payment proof.")}
          </div>

          {image && (
            <div className="relative w-40">
              <img
                className="h-40  object-contain"
                alt="selected"
                src={image}
              />
              <button
                className="absolute top-0 right-0 text-red-500 text-xl text-center rounded-full bg-white h-6 w-6 flex items-center justify-center"
                onClick={() => setImage(null)}
              >
                X
              </button>
            </div>
          )}

          {uploadImageStatus === "loading" ? (
            <LoadingSpinner />
          ) : (
            <>
              {image ? (
                <PEButton
                  name={t("Upload image")}
                  type={"button"}
                  onClick={uploadImg}
                />
              ) : (
                <UploadImage onChange={handleFileChange} />
              )}
            </>
          )}
        </div>

        {rpstatus === "loading" ? (
          <LoadingSpinner />
        ) : (
          <PEButton
            disabled={!uploadResult}
            name={t("Finish payment")}
            type={"button"}
            onClick={() => {
              if (!selectedPayment && !uploadResult && !userInfo) {
                return
              }

              if (!selectedPayment) {
                return toast.error(t("Please select your payment method"), {
                  position: toast.POSITION.TOP_RIGHT,
                })
              }

              if (!uploadResult?.filename) {
                return toast.error(t("Please upload you payment proof photo"), {
                  position: toast.POSITION.TOP_RIGHT,
                })
              }

              let userId
              if (loginUserInfo) {
                if ("userId" in loginUserInfo) {
                  userId = loginUserInfo.userId
                }
              }

              dispatch(
                registrationPaymentAsync({
                  paymentMethod: selectedPayment?.name,
                  paymentImageUrl: uploadResult?.filename ?? "",
                  userId: userInfo?.id ?? userId ?? 0,
                }),
              )
            }}
          />
        )}
      </div>
    </div>
  )
}
