import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { User, getProfileApi } from "./profileApi"

export interface UserState {
  user: User | null
  status: "idle" | "loading" | "rejected"
}

const initialState: UserState = {
  user: null,
  status: "idle",
}

export const getProfileAsync = createAsyncThunk(
  "training category",
  async () => {
    const response = await getProfileApi()
    return response
  },
)

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProfileAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getProfileAsync.fulfilled, (state, action) => {
        state.status = "idle"

        state.user = action.payload
      })
      .addCase(getProfileAsync.rejected, (state) => {
        state.status = "rejected"
      })
  },
})

export default profileSlice.reducer
