import { axiosInstance } from "../../app/service/api"
import { getUser } from "../../utils/helpers"
import { IUpdateProfileForm } from "./useUpdateProfileHook"

export interface UpdateProfileResponse {
  id: number
  fullName: string
  phoneNumber: string
  country: string
  description: string
  imageUrl: string
  status: string
  role: string
}

export const updateProfileApi = async (formData: IUpdateProfileForm) => {
  const user = getUser()

  try {
    const response = await axiosInstance.put<UpdateProfileResponse>(
      `/auth/user/${user.id}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      },
    )

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
