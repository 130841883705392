import { Dialog, Transition } from "@headlessui/react"
import { Fragment, useEffect } from "react"
import Button from "../Input/button"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { getSupportAsync } from "./supportSlice"
import LoadingSpinner from "../loading"

export default function CustomerSupportModal(props: any) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { status, support } = useAppSelector((state) => state.customerSupport)

  useEffect(() => {
    dispatch(getSupportAsync())
  }, [dispatch])

  return (
    <>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10 flex flex-col justify-center items-center"
          onClose={props.closeModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6  align-middle shadow-xl transition-all text-center">
                  <Dialog.Title className="text-xl font-bold mb-6">
                    {t("Customer Support")}
                  </Dialog.Title>
                  {status === "loading" && <LoadingSpinner />}{" "}
                  {support.length > 0 && (
                    <div className="flex flex-col space-y-4 ">
                      {support.map((pn, index) => {
                        return (
                          <a href={`tel:+251${pn.phoneNumbers[0]}`} key={index}>
                            +251{pn.phoneNumbers[0]}
                          </a>
                        )
                      })}
                    </div>
                  )}
                  <Button btnName={t("Close")} onClick={props.closeModal} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
