import { Link, useRouteError } from "react-router-dom"

export default function ErrorBoundary() {
  let error = useRouteError()
  console.error(error)
  // Uncaught ReferenceError: path is not defined
  return (
    <div className="flex h-screen items-center justify-center">
      <div className="p-4 rounded-lg">
        <h2 className="text-2xl font-bold mb-4">Something went wrong!</h2>
        <p className="mb-4">
          We're working on fixing the issue. Please try again later.
        </p>
        <Link
          to="/"
          className="bg-primary text-white px-4 py-2 rounded-md hover:bg-cyan-800"
        >
          Go Back
        </Link>
      </div>
    </div>
  )
}
