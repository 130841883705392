import {
  DeepMap,
  FieldError,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister,
} from "react-hook-form"
import get from "lodash.get"
import { ErrorMessage } from "@hookform/error-message"

interface InputProps<TFormValues extends FieldValues> {
  type: string
  name: Path<TFormValues>
  register: UseFormRegister<TFormValues>
  rules?: RegisterOptions
  errors?: Partial<DeepMap<TFormValues, FieldError>>
  placeholder?: string
  accept?: string
  id?: string
  className?: string
  label: string
}

const PETextinput = <TFormValues extends FieldValues>({
  type,
  name,
  register,
  rules,
  errors,
  placeholder,
  accept,
  id,
  className,
  label,
}: InputProps<TFormValues>) => {
  const errorMessages = get(errors, name)
  const hasError = !!(errors && errorMessages)

  const classN = getInputTypeStyles(type, hasError)
  return (
    <div>
      <label
        htmlFor="name"
        className="block text-sm font-medium leading-6 text-gray-900 mb-4"
      >
        {label}
      </label>
      <div className="flex">
        {name === "phoneNumber" && (
          <div className="w-[58px] h-10 px-4 py-[15px] bg-blue-900 rounded-[5px] justify-center items-center  inline-flex mr-2">
            <div className="text-white text-base font-medium font-['Inter']">
              +251
            </div>
          </div>
        )}
        <input
          id={id}
          type={type}
          placeholder={placeholder}
          className={className || classN}
          accept={accept}
          {...register(name, rules)}
        />
      </div>
      <ErrorMessage
        errors={errors}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        name={name as any}
        render={({ message }) => (
          <p className="mt-1 font-serif text-sm text-left block text-error">
            {message}
          </p>
        )}
      />
    </div>
  )
}
const getInputTypeStyles = (type: string, hasError: boolean): string => {
  const defaultInputSyle =
    "p-2 px-4 text-start text-primary border border-primary w-full placeholder:text-xs placeholder:text-start md:text-left placeholder:md:text-left focus:outline-none rounded-md focus:ring-primary focus:ring-1 "
  const errorInputStyle =
    "p-2 px-4 text-start  border border-error w-full placeholder:text-xs placeholder:text-start md:text-left placeholder:md:text-left focus:outline-none rounded-md focus:ring-error focus:ring-1"
  switch (type) {
    case "checkbox":
      return "form-checkbox h-5 w-5 accent-primary"
    case "text":
      return hasError ? errorInputStyle : defaultInputSyle
    case "number":
      return hasError ? errorInputStyle : defaultInputSyle
    case "password":
      return hasError ? errorInputStyle : defaultInputSyle
    case "file":
      return "hidden"
    default:
      return ""
  }
}
export default PETextinput
