import { axiosInstance } from "../../app/service/api"

export interface TermsAndCondition {
  id: number
  pdfUrl: string
  createdAt: string
  updatedAt: string
}

export const getTermsAndConditionApi = async () => {
  try {
    const response = await axiosInstance.get<TermsAndCondition[]>(
      "termsAndCondition",
    )

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
