import { useNavigate } from "react-router-dom"
import ImageSlide from "../ImageSlide/ImageSlide"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { useEffect } from "react"
import { getTrainingCategoryAsync } from "../TrainingCategory/trainingCategorySlice"
import TrainingCard from "../TrainingCategory/TrainingCard"
import LoadingSpinner from "../../components/loading"
import { useTranslation } from "react-i18next"

export default function Home() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { trainingCategory, status } = useAppSelector(
    (state) => state.trainingCategory,
  )

  useEffect(() => {
    dispatch(getTrainingCategoryAsync())
  }, [dispatch])

  return (
    <div className="mx-auto max-w-6xl px-2 sm:px-6 lg:px-8">
      <div className="w-full">
        <ImageSlide />
      </div>

      <section className="p-4 md:px-8  py-4 md:py-10 mt-10 rounded-md ">
        <div className="text-center">
          <h1 className="text-2xl">{t("Take trainings")}</h1>
        </div>
      </section>

      <section className="mt-10 ">
        {status === "loading" ? (
          <LoadingSpinner />
        ) : (
          <>
            {trainingCategory.map((category) => {
              return (
                <TrainingCard
                  key={category.id}
                  image={category.imageUrl}
                  onRoute={() =>
                    navigate(`training/${category.id}`, {
                      state: category.title,
                    })
                  }
                  title={category.title}
                  description={category.description}
                />
              )
            })}
          </>
        )}
      </section>
    </div>
  )
}
