import { useNavigate } from "react-router-dom"
import Container from "../../components/Container"
import LanguageSwitcher from "../localization/LanguageSwitcher"
import { useTranslation } from "react-i18next"

const Language = () => {
  let navigate = useNavigate()

  const { t } = useTranslation()

  return (
    <Container>
      <div className="flex flex-col h-screen justify-center items-center">
        <div className="self-stretch grow shrink basis-0 p-5 flex-col justify-start items-center gap-[87px] inline-flex">
          <div className="w-[160px] h-[160px] rounded-[100px] bg-secondary p-6">
            <img src="/logo.png" alt="power ethio" />
          </div>
          <div className="flex-col justify-center items-center gap-5 flex">
            <div className="text-center text-black text-[28px] font-normal font-['Nyala'] leading-tight">
              ቋንቋ ይምረጡ
            </div>
            <div className="text-center text-black text-2xl font-semibold font-['Inter'] leading-tight">
              Choose Language
            </div>
          </div>

          <p>{t("greeting")}</p>

          <div className="flex-col justify-center items-center gap-[146px] flex">
            <LanguageSwitcher fromProfile={false} />

            <button
              className="w-full px-5 py-[12px] bg-blue-900 rounded-[10px] justify-center items-center gap-2.5 inline-flex"
              onClick={() => navigate("/aboutOnBoarding")}
            >
              <div className="text-center text-white text-[28px] font-normal font-['Nyala'] leading-tight">
                {t("continue")}
              </div>
            </button>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Language
