export interface BtnProps {
  name: string
  type: "submit" | "reset" | "button" | undefined
  onClick?: () => void
  disabled?: boolean
  redBtn?: boolean
}

const PEButton = ({ name, type, disabled, redBtn, onClick }: BtnProps) => {
  const redBtnClassName =
    "mt-6 flex w-full justify-center rounded-md bg-error px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-error focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-error"

  const disabledBtnClassName =
    "mt-6 flex w-full justify-center rounded-md bg-slate-400 shadow-lg px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-grey-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-grey-800"
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      type={type}
      className={
        disabled
          ? disabledBtnClassName
          : redBtn
          ? redBtnClassName
          : "mt-6 flex w-full justify-center rounded-md bg-primary px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
      }
    >
      {name}
    </button>
  )
}

export default PEButton
