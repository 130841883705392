import { createSlice } from "@reduxjs/toolkit"
import { Course } from "./courseApi"
import { getCourseByTrainingIdAsync } from "./courseThunk"

interface CourseState {
  status: "idle" | "loading" | "rejected"
  error: string | undefined
  courses: Course[]
}

const initialState: CourseState = {
  status: "idle",
  error: undefined,
  courses: [],
}

const courseSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getCourseByTrainingIdAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getCourseByTrainingIdAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.courses = action.payload
      })
      .addCase(getCourseByTrainingIdAsync.rejected, (state, action) => {
        state.status = "rejected"
        state.error = action.error.message
      })
  },
})

export default courseSlice.reducer
