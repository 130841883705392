import { axiosInstance } from "../../app/service/api"
import { getToken } from "../../utils/helpers"

export interface ImageSlide {
  id: number
  imageUrls: string[]
  createdAt: string
  updatedAt: string
}

export const fetchImageSlideApi = async () => {
  let token = getToken()

  try {
    const response = await axiosInstance.get<ImageSlide[]>("carouselImage", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
