import { axiosInstance } from "../../app/service/api"
import { getToken } from "../../utils/helpers"

export interface Training {
  id: number
  title: string
  imageUrl: string
  description: string
  trainingCategoryId: number
  createdAt: string
  updatedAt: string
}

export interface GetTrainingProps {
  id: number
  perPage?: number
  page?: number
}

export const getTrainingByCategoryIdApi = async (props: GetTrainingProps) => {
  let token = getToken()
  try {
    const response = await axiosInstance.get<Training[]>(
      `/training/category/${props.id}?page=${props.page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
