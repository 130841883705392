import { createAsyncThunk } from "@reduxjs/toolkit"
import { getAboutApi } from "./aboutApi"

export const getAboutAsyc = createAsyncThunk("about", async () => {
  try {
    const response = await getAboutApi()
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
})
