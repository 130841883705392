import { createAsyncThunk } from "@reduxjs/toolkit"
import { IUpdateProfileForm } from "./useUpdateProfileHook"
import { updateProfileApi } from "./updateProfileAPI"

export const updateProfileAsync = createAsyncThunk(
  "updateProfile",
  async (formData: IUpdateProfileForm) => {
    try {
      const response = await updateProfileApi(formData)
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  },
)
