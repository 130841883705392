const Button = (props: any) => {
  return (
    <button
      onClick={props.onClick}
      className="mt-6 flex w-full justify-center rounded-md bg-primary px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
    >
      {props.btnName}
    </button>
  )
}

export default Button
