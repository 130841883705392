import { createAsyncThunk } from "@reduxjs/toolkit"
import { GetTrainingProps, getTrainingByCategoryIdApi } from "./trainingApi"

export const getTrainingByCategoryIdAsync = createAsyncThunk(
  "training/categoryId",
  async (props: GetTrainingProps) => {
    try {
      const response = await getTrainingByCategoryIdApi(props)
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  },
)
