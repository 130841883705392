import { useEffect, useState } from "react"
import CustomerSupportModal from "../../components/Modal/CustomerSupportModal"
import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import LoadingSpinner from "../../components/loading"
import { getProfileAsync } from "./profileSlice"
import PEButton from "../../components/Input/PEPutton"
import { useTranslation } from "react-i18next"
import FeedbackModal from "../Feedback/FeedbackModal"
import { logoutAsync } from "../login/loginThunk"
import LanguageSwitcher from "../localization/LanguageSwitcher"

export default function Profile() {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const { user, status } = useAppSelector((state) => state.profile)
  const { status: loginStatus } = useAppSelector((state) => state.login)

  const [modals, setModals] = useState({
    cs: false,
    fb: false,
  })

  const openModal = (modalName: "cs" | "fb") => {
    setModals((prevModals) => ({ ...prevModals, [modalName]: true }))
  }

  const closeModal = (modalName: "cs" | "fb") => {
    setModals((prevModals) => ({ ...prevModals, [modalName]: false }))
  }

  useEffect(() => {
    dispatch(getProfileAsync())
  }, [dispatch])

  if (status === "loading") {
    return (
      <div className="flex items-center justify-center h-screen">
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <div className="container mt-10 flex flex-col items-center justify-center mx-auto max-w-7xl px-2 md:px-8">
      <CustomerSupportModal
        isOpen={modals.cs}
        closeModal={() => closeModal("cs")}
        openModal={() => openModal("cs")}
      />

      <FeedbackModal
        isOpen={modals.fb}
        closeModal={() => closeModal("fb")}
        openModal={() => openModal("fb")}
      />
      <div className="flex h-full justify-center items-center">
        <div className="h-32 w-32 rounded-[100px] bg-secondary p-4 mb-4 ">
          <img src="/logo.png" alt="power ethio" />
        </div>
      </div>

      <p className="text-justify mb-4">{user?.fullName}</p>
      <p className="text-justify font-bold">{user?.phoneNumber}</p>

      <div className="mt-10 mx-auto w-full max-w-screen-md mb-10">
        <div className="space-y-4 bg-white p-4 rounded-lg shadow-md pb-10">
          <PEButton
            name={t("About Power Ethiopia")}
            type="button"
            onClick={() => navigate("/about")}
          />
          <PEButton
            name={t("Update profile")}
            type="button"
            onClick={() => navigate("/profile/update")}
          />

          <LanguageSwitcher fromProfile />

          <PEButton
            name={t("Delivery Address")}
            type="button"
            onClick={() => navigate("/delivery")}
          />
          <PEButton
            name={t("Customer Support")}
            type="button"
            onClick={() => openModal("cs")}
          />
          <PEButton
            name={t("Give Feedback")}
            type="button"
            onClick={() => openModal("fb")}
          />
          <PEButton
            name={t("Terms & Conditions")}
            type="button"
            onClick={() => navigate("/termsAndConditions")}
          />
        </div>

        {loginStatus === "loading" ? (
          <LoadingSpinner />
        ) : (
          <PEButton
            name={t("Logout")}
            type={"button"}
            onClick={async () => {
              await dispatch(logoutAsync())
              navigate("/login")
            }}
          />
        )}
      </div>
    </div>
  )
}
