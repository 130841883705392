import { Dialog, Transition } from "@headlessui/react"
import { Fragment } from "react"
import Button from "../Input/button"

export default function MyModal(props: any) {
  return (
    <>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={props.closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    <div className="flex h-full justify-center items-center">
                      <div className="h-36 w-36 rounded-[100px] bg-secondary p-4 my-6">
                        <img src="/logo.png" alt="power ethio" />
                      </div>
                    </div>
                  </Dialog.Title>
                  <div className="text-center text-blue-900 text-4xl font-medium font-['Poppins'] mb-4">
                    Success
                  </div>

                  <Dialog.Description className=" text-center text-stone-900 text-base font-normal mb-4 ">
                    You have successfully applied for a credit, please contact
                    our customer support and finish your process in person.
                  </Dialog.Description>

                  <Dialog.Panel className="text-center text-neutral-800 text-[32px] font-semibold font-['Inter']">
                    +251-973-234-137
                  </Dialog.Panel>

                  <Button btnName="Close" onClick={props.closeModal} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
