import { RouterProvider } from "react-router-dom"
import { router } from "./app/router"
import { useAppDispatch } from "./app/hooks"
import { useEffect } from "react"
import { loadTranslations } from "./features/localization/slices/localizationSlice"

export const App = () => {
  const dispatch = useAppDispatch()
  const lastPickedLanguage = localStorage.getItem("language")

  useEffect(() => {
    if (lastPickedLanguage) {
      dispatch(loadTranslations(lastPickedLanguage))
    }
  }, [dispatch, lastPickedLanguage])

  return <RouterProvider router={router} />
}
