import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { axiosInstance } from "../../app/service/api"

export interface PaymentResponse {
  id: number
  name: string
  imageUrl: string
  accountNumber: string
  createdAt: string
  updatedAt: string
}

export const getPaymentApi = async () => {
  try {
    const response = await axiosInstance.get<PaymentResponse[]>("payment")

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getpaymentAsync = createAsyncThunk("payment", async () => {
  try {
    const response = await getPaymentApi()
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
})

interface PaymentState {
  status: "idle" | "loading" | "rejected"
  error: string | undefined
  paymentResponse: null | PaymentResponse[]
}

const initialState: PaymentState = {
  status: "idle",
  error: undefined,
  paymentResponse: null,
}

export const paymentSlice = createSlice({
  name: "payment",
  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getpaymentAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getpaymentAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.paymentResponse = action.payload
      })
      .addCase(getpaymentAsync.rejected, (state, action) => {
        state.status = "rejected"
        state.error = action.error.message
      })
  },
})

export default paymentSlice.reducer
