import { createAsyncThunk } from "@reduxjs/toolkit"
import { getTermsAndConditionApi } from "./termsAndConditionApi"

export const getTermsAndConditionAsyc = createAsyncThunk(
  "termsAndCondition",
  async () => {
    try {
      const response = await getTermsAndConditionApi()
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  },
)
