/* eslint-disable jsx-a11y/no-redundant-roles */

import Button from "../../components/Input/button"
import { Link, useNavigate } from "react-router-dom"
import { Product } from "../Product/productApi"
import { getCompleteImageUrl } from "../../utils/helpers"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { removeFromCart } from "./cartSlice"
import { addProducts, addTotalPrice } from "../Checkout/checkoutSlice"
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/outline"
import { useEffect, useState } from "react"
import { Products } from "../Checkout/checkoutApi"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"

export default function Cart() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const cartData = localStorage.getItem("cart")

  const [quantity, setQuantity] = useState<Products[]>([])

  const [isAgreed, setIsAgreed] = useState(false)

  const { products } = useAppSelector((state) => state.cart)

  const total = products.reduce((total, product) => {
    return total + Number(product.price)
  }, 0)

  const handleRemoveFromCart = (product: Product) => {
    toast.info(t("Removed from cart"), {
      position: toast.POSITION.TOP_RIGHT,
    })
    dispatch(removeFromCart(product))
  }

  useEffect(() => {
    if (products) {
      setQuantity([])
      products.map((product) =>
        setQuantity((prevQuantity) => {
          const index = prevQuantity.findIndex((item) => item.id === product.id)
          if (index === -1) {
            return [...prevQuantity, { id: product.id, quantity: 1 }]
          } else {
            const updatedQuantity = prevQuantity[index].quantity
            const updatedItem = {
              ...prevQuantity[index],
              quantity: updatedQuantity,
            }
            const updatedQuantityList = [...prevQuantity]
            updatedQuantityList[index] = updatedItem
            return updatedQuantityList
          }
        }),
      )
    }
  }, [products, cartData])

  function increaseQuantity(product: Product) {
    setQuantity((prevQuantity) => {
      const index = prevQuantity.findIndex((item) => item.id === product.id)
      if (index === -1) {
        return [...prevQuantity, { id: product.id, quantity: 1 }]
      } else {
        const updatedQuantity = prevQuantity[index].quantity + 1
        const updatedItem = {
          ...prevQuantity[index],
          quantity: updatedQuantity,
        }
        const updatedQuantityList = [...prevQuantity]
        updatedQuantityList[index] = updatedItem
        return updatedQuantityList
      }
    })
  }

  function decreaseQuantity(product: Product) {
    setQuantity((prevQuantity) => {
      const index = prevQuantity.findIndex((item) => item.id === product.id)
      if (index === -1) {
        return [...prevQuantity, { id: product.id, quantity: 1 }]
      } else {
        const updatedQuantity = prevQuantity[index].quantity - 1
        const updatedItem = {
          ...prevQuantity[index],
          quantity: updatedQuantity,
        }
        const updatedQuantityList = [...prevQuantity]
        updatedQuantityList[index] = updatedItem
        return updatedQuantityList
      }
    })
  }

  if (products.length === 0) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-center p-10 text-2xl">{t("Cart is empty")}</p>
      </div>
    )
  }

  return (
    <div className="flex flex-col w-screen justify-center items-center ">
      <div className="flex h-full max-w-max flex-col rounded-lg">
        <div className="flex-1 px-4 py-6 sm:px-6">
          <div className="flex items-start justify-between">
            <p className="text-lg font-medium text-gray-900">{t("Cart")}</p>
          </div>

          <div className="mt-8">
            <div className="flow-root">
              <ul role="list" className="-my-6 divide-y divide-gray-200">
                {products.map((product) => (
                  <div
                    className="bg-white py-6  px-4 rounded-lg my-4 "
                    key={product.id}
                  >
                    <li key={product.id} className="flex ">
                      <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                        <img
                          src={getCompleteImageUrl(product.imageUrl[0])}
                          alt={product.name}
                          className="h-full w-full object-cover object-center "
                        />
                      </div>

                      <div className="ml-4 flex flex-1 flex-col">
                        <div>
                          <div className="flex justify-between text-base font-medium text-gray-900">
                            <h3>
                              <p
                                onClick={() =>
                                  navigate(`/product/${product.id}`)
                                }
                                className="line-clamp-2 text-ellipsis hover:cursor-pointer hover:text-gray-600 "
                              >
                                {product.name}
                              </p>
                              <p className="line-clamp-1 max-w-screen-sm">
                                {product.description}
                              </p>
                            </h3>
                            <p className="ml-4">
                              {product.price} <strong>ETB</strong>
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-1 items-end justify-between text-sm">
                          <div className="flex">
                            <button
                              type="button"
                              className="font-bold text-md text-error hover:text-red-700"
                              onClick={() => handleRemoveFromCart(product)}
                            >
                              {t("Remove")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                    <div className="flex justify-between bg-primary px-6 h-10 text-white  md:w-1/3 py-2 rounded-full mt-6">
                      <MinusCircleIcon
                        onClick={() => decreaseQuantity(product)}
                      />

                      {getQuantityValue(quantity, product.id)}
                      <PlusCircleIcon
                        onClick={() => {
                          increaseQuantity(product)
                        }}
                      />
                    </div>
                    <p className="px-2 font-light mt-2">{t("Quantity")}</p>
                  </div>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
          <div className="space-y-3">
            <div className="flex justify-between text-base font-medium text-gray-900">
              <p>{t("Total")}</p>
              <p>
                {total} <strong> ETB</strong>
              </p>
            </div>
            {/* <div className="flex justify-between text-base font-medium text-gray-900  ">
              <p>{t("Tax")}</p>
              <p>
                <strong> 15%</strong>
              </p>
            </div>

            <div className="h-1 w-full bg-white"></div>

            <div className="flex justify-between text-base font-medium text-gray-900">
              <p>{t("Subtotal")}</p>
              <p>
                {total * (15 / 100) + total} <strong>ETB</strong>{" "}
              </p>
            </div> */}
          </div>

          <p className="mt-0.5 text-sm text-gray-500">
            {t("Shipping and taxes calculated at checkout.")}
          </p>

          <div className="mt-6 flex text-grayText text-sm text-justify ">
            <input
              type="checkbox"
              className="mx-4"
              onChange={(e) => setIsAgreed(e.target.checked)}
              checked={isAgreed}
            />
            <Link
              to="/termsAndConditions"
              className="underline underline-offset-2"
              target="_blank"
            >
              {t("Agree to our terms and conditions")}
            </Link>
          </div>

          <div className="mt-6">
            <Button
              btnName={t("Proceed to payment")}
              onClick={() => {
                if (quantity.length === 0) {
                  return toast.error(t("Please set product quantity"), {
                    position: toast.POSITION.TOP_RIGHT,
                  })
                }

                if (!isAgreed) {
                  return toast.error(t("Agree to our terms and conditions"), {
                    position: toast.POSITION.TOP_RIGHT,
                  })
                }

                dispatch(addProducts(quantity))
                dispatch(addTotalPrice(total))
                navigate("/checkout")
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const getQuantityValue = (quantity: Products[], productId: number) => {
  const productIndex = quantity.findIndex((product) => product.id === productId)
  if (productIndex === -1) return 0
  return quantity[productIndex].quantity
}
