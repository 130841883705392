import React from "react"

interface ContainerProps {
  children: React.ReactNode
}

const Container: React.FC<ContainerProps> = ({ children }) => {
  return (
    <div className="container flex flex-col items-center justify-center mx-auto max-w-7xl px-2 md:px-8">
      {children}
    </div>
  )
}

export default Container
