import { createAsyncThunk } from "@reduxjs/toolkit"
import { Credit, addCreditApi, getCreditCategoryApi } from "../Credit/creditApi"

export const getCreditCategoryAsync = createAsyncThunk(
  "creditCategory",
  async () => {
    try {
      const response = await getCreditCategoryApi()
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  },
)

export const addCreditAsync = createAsyncThunk(
  "credit",
  async (formData: Credit) => {
    try {
      const response = await addCreditApi(formData)
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  },
)
