import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { axiosInstance } from "../../app/service/api"
import { toast } from "react-toastify"

export interface UploadedImageReponse {
  message: string
  filename: string
}

export interface UploadedMultipleImageReponse {
  message: string
  filenames: string[]
}

export const uploadImageApi = async (formData: File) => {
  try {
    const response = await axiosInstance.post<UploadedImageReponse>(
      "uploads",
      { file: formData },
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      },
    )

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const uploadMultipleImageApi = async (formData: File[]) => {
  const data = new FormData()
  for (const image of formData) {
    data.append("files", image)
  }

  try {
    const response = await axiosInstance.post<UploadedMultipleImageReponse>(
      "uploads/multiple",
      data,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      },
    )
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const uploadImageAsync = createAsyncThunk(
  "upload",
  async (formData: File) => {
    try {
      const response = await uploadImageApi(formData)
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  },
)

export const uploadMultipleImageAsync = createAsyncThunk(
  "upload/multiple",
  async (formData: File[]) => {
    try {
      const response = await uploadMultipleImageApi(formData)
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  },
)

interface UploadImageState {
  status: "idle" | "loading" | "rejected"
  mstatus: "idle" | "loading" | "rejected"
  error: string | undefined
  uploadResult: null | UploadedImageReponse
  muploadResult: null | UploadedMultipleImageReponse
}

const initialState: UploadImageState = {
  status: "idle",
  mstatus: "idle",
  error: undefined,
  uploadResult: null,
  muploadResult: null,
}

export const uploadImageSlice = createSlice({
  name: "uploadImage",
  initialState,

  reducers: {
    removeImage: (state) => {
      state.uploadResult = null
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(uploadImageAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(uploadImageAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.uploadResult = action.payload

        toast.success(`Image upload success`, {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .addCase(uploadImageAsync.rejected, (state, action) => {
        state.status = "rejected"
        state.error = action.error.message
        toast.error(`Error while uploading image ${action.error.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        })
      })

      .addCase(uploadMultipleImageAsync.pending, (state) => {
        state.mstatus = "loading"
      })
      .addCase(uploadMultipleImageAsync.fulfilled, (state, action) => {
        state.mstatus = "idle"
        state.muploadResult = action.payload

        toast.success(`Image upload success`, {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .addCase(uploadMultipleImageAsync.rejected, (state, action) => {
        state.mstatus = "rejected"
        state.error = action.error.message
        toast.error(`Error while uploading image ${action.error.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  },
})

export const { removeImage } = uploadImageSlice.actions

export default uploadImageSlice.reducer
