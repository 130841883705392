import { axiosInstance } from "../../app/service/api"

export interface About {
  id: number
  title: string
  pdfUrl: string
  websiteUrl: string
  createdAt: string
  updatedAt: string
}

export const getAboutApi = async () => {
  try {
    const response = await axiosInstance.get<About[]>("about")

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
