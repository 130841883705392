import { axiosInstance } from "../../app/service/api"
import { getToken } from "../../utils/helpers"

export interface Product {
  id: number
  name: string
  imageUrl: string[]
  videoUrl: string[]
  isInStock: boolean
  description: string
  pdfUrl: string
  price: string
  shortDescription: string
  createdAt: string
  updatedAt: string
}

export const getProductApi = async (page: number) => {
  let token = getToken()

  try {
    const response = await axiosInstance.get<Product[]>(
      `product?page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getProductByIdApi = async (id: number) => {
  let token = getToken()

  try {
    const response = await axiosInstance.get<Product>(`product/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const searchProductApi = async (q: string) => {
  let token = getToken()

  try {
    const response = await axiosInstance.get<Product[]>(
      `product/search/query?q=${q}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
