import { Menu, Transition } from "@headlessui/react"
import { Fragment, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import { addDeliveryLocation } from "../features/Checkout/checkoutSlice"
import LoadingSpinner from "./loading"
import {
  DeliveryResponse,
  getDeliveryAsync,
} from "../features/Delivery/deliverySlice"
import { useTranslation } from "react-i18next"
import PEButton from "./Input/PEPutton"
import { useNavigate } from "react-router-dom"

export default function Dropdown() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [selectedDelivery, setSelectedDelivery] = useState<
    DeliveryResponse | undefined
  >(undefined)

  const { deliveryResponse, status } = useAppSelector((state) => state.delivery)

  useEffect(() => {
    if (selectedDelivery) {
      dispatch(addDeliveryLocation(selectedDelivery.id))
    }
  }, [dispatch, selectedDelivery])

  useEffect(() => {
    dispatch(getDeliveryAsync())
  }, [dispatch])

  return (
    <>
      {status === "loading" ? (
        <div className="my-4">
          <LoadingSpinner />
        </div>
      ) : deliveryResponse.length === 0 ? (
        <div>
          <p>{t("You don't have delivery address added yet")}</p>
          <PEButton
            name={t("Add delivery address")}
            type={"button"}
            onClick={() => navigate("/delivery")}
          />
        </div>
      ) : (
        <div className="my-4">
          <Menu as="div" className="relative inline-block text-left w-full">
            <div>
              <Menu.Button className="inline-flex justify-center w-full px-4 py-4 text-sm font-medium  bg-white rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 border-2 border-black">
                {selectedDelivery?.name ?? t("Delivery Location")}
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                className="w-full absolute left-0  mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                // style={{ height: "200px", overflowY: "scroll" }}
              >
                {deliveryResponse.map((item, index) => {
                  return (
                    <div key={index} className="px-1 py-1 ">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${
                              active ? "bg-primary text-white" : "text-gray-900"
                            } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                            onClick={() => {
                              setSelectedDelivery(item)
                            }}
                          >
                            {item.name}, {item.address}, {item.phoneNumber}
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  )
                })}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      )}
    </>
  )
}
