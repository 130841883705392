import MyModal from "../../components/Modal/SuccessModal"
import { useCreditHook } from "./useCreditHook"
import PETextinput from "../../components/Input/Input"
import PEButton from "../../components/Input/PEPutton"
import LoadingSpinner from "../../components/loading"
import UploadMultipleImage from "../ImageUpload/uploadMultipleImage"
import { useTranslation } from "react-i18next"

export default function Credit() {
  const { t } = useTranslation()
  const {
    handleSubmit,
    onSubmit,
    closeModal,
    openModal,
    register,
    isOpen,
    creditType,
    errors,
    status,
    mstatus,
    handleMultipleFile,
    uploadImg,
    image,
    handleRemoveImage,
  } = useCreditHook()
  return (
    <div className="mt-10  mx-auto w-full max-w-screen-sm flex flex-col ">
      <MyModal isOpen={isOpen} closeModal={closeModal} openModal={openModal} />

      <div className="p-4 rounded-lg border-2 border-neutral-300 mb-6">
        <p className="text-justify text-grayText">
          {t(
            "Provide your full information to apply credit for power Ethiopia before proceeding to payment.",
          )}
        </p>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-6 bg-white p-4 rounded-lg shadow-xl md:p-10 "
      >
        <PETextinput
          type={"text"}
          name={"fullName"}
          label={t("Full name")}
          placeholder={t("Enter your full name")}
          rules={{ required: t("Name is required") }}
          register={register}
          errors={errors}
        />

        <PETextinput
          type={"number"}
          name={"phoneNumber"}
          label={t("Phone number")}
          placeholder={t("Enter your phone number")}
          rules={{ required: t("Phone number is required") }}
          register={register}
          errors={errors}
        />

        {creditType === "Commercial" && (
          <>
            <PETextinput
              type={"text"}
              name={"fieldOfBusiness"}
              label={t("Field of Business")}
              placeholder={t("Enter your field of business")}
              rules={{ required: t("Field of business is required") }}
              register={register}
              errors={errors}
            />

            <PETextinput
              type={"text"}
              name={"tradeName"}
              label={"Trade name"}
              placeholder={t("Enter your trade name")}
              rules={{ required: t("Trade name is required") }}
              register={register}
              errors={errors}
            />
          </>
        )}
        <PETextinput
          type={"text"}
          name={"address"}
          label={t("Address")}
          placeholder={t("Enter your address")}
          rules={{ required: t("Address is required") }}
          register={register}
          errors={errors}
        />

        <PETextinput
          type={"number"}
          name={"amount"}
          label={t("Credit amount")}
          placeholder={t("Enter credit amount")}
          rules={{ required: t("Credit amount is required") }}
          register={register}
          errors={errors}
        />
        {image.length > 0 && (
          <div className="flex ">
            {image.map((img, index) => {
              const imageUrl = URL.createObjectURL(img)
              return (
                <div className="relative" key={index}>
                  <img src={imageUrl} alt="" height={100} width={100} />
                  <button
                    className="absolute top-0 right-0 h-6 w-6 bg-white shadow-md rounded-full text-red-500"
                    onClick={() => handleRemoveImage(index)}
                  >
                    X
                  </button>
                </div>
              )
            })}
          </div>
        )}
        {mstatus === "loading" ? (
          <LoadingSpinner />
        ) : (
          <>
            {image.length > 0 ? (
              <PEButton
                name={"Upload images"}
                type={"button"}
                onClick={uploadImg}
              />
            ) : (
              <UploadMultipleImage onImagesChange={handleMultipleFile} />
            )}
          </>
        )}
        <div className="text-justify text-neutral-800 text-[12px] font-normal ">
          {t("Please upload your business license document and ID")}
        </div>

        {status === "loading" ? (
          <LoadingSpinner />
        ) : (
          <PEButton name={t("Apply for credit")} type="submit" />
        )}
      </form>
    </div>
  )
}
