import { getCompleteImageUrl } from "../utils/helpers"

export default function PDFViewer(props: any) {
  return (
    <iframe
      title={props.title}
      src={getCompleteImageUrl(props.pdfUrl) + "#toolbar=0&#zoom=50"}
      className="w-[400px] md:w-[1200px] h-[500px] md:h-[800px]"
      style={{
        overflow: "hidden",
        overflowX: "hidden",
        overflowY: "hidden",
      }}
    />
  )
}
