import { createAsyncThunk } from "@reduxjs/toolkit"
import { aboutOnBoardingApi } from "./aboutOnBoardingApi"

export const getAboutOnBoardingAsyc = createAsyncThunk(
  "aboutOnBoarding",
  async () => {
    try {
      const response = await aboutOnBoardingApi()
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  },
)
