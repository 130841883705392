import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import localizationReducer from "../features/localization/slices/localizationSlice"
import loginSlice from "../features/login/loginSlice"
import registerSlice from "../features/register/registerSlice"
import imageSlideSlice from "../features/ImageSlide/imageSlideSlice"
import trainingCategorySlice from "../features/TrainingCategory/trainingCategorySlice"
import trainingSlice from "../features/Training/trainingSlice"
import courseSlice from "../features/Course/courseSlice"
import productsSlice from "../features/Product/productsSlice"
import creditSlice from "../features/Credit/creditSlice"
import aboutOnBoardingSlice from "../features/AboutOnBoarding/aboutOnBoardingSlice"
import cartSlice from "../features/Cart/cartSlice"
import profileSlice from "../features/Profile/profileSlice"
import updateProfileSlice from "../features/UpdateProfile/updateProfileSlice"
import uploadImageSlice from "../features/ImageUpload/uploadImageSlice"
import paymentSlice from "../features/Payment/paymentSlice"
import registrationPaymentSlice from "../features/RegistrationPayment/registartionPaymentSlice"
import checkoutSlice from "../features/Checkout/checkoutSlice"
import notificationSlice from "../features/Notification/notificationSlice"
import deliverySlice from "../features/Delivery/deliverySlice"
import countrySlice from "../features/Country/countrySlice"
import feedbackSlice from "../features/Feedback/feedbackSlice"
import aboutSlice from "../features/About/aboutSlice"
import termsAndConditionSlice from "../features/TermsAndConditions/termsAndConditionSlice"
import supportSlice from "../components/Modal/supportSlice"

export const store = configureStore({
  reducer: {
    login: loginSlice,
    register: registerSlice,
    localization: localizationReducer,
    imageSlide: imageSlideSlice,
    trainingCategory: trainingCategorySlice,
    trainings: trainingSlice,
    course: courseSlice,
    product: productsSlice,
    credit: creditSlice,
    aboutOnBoarding: aboutOnBoardingSlice,
    cart: cartSlice,
    profile: profileSlice,
    updateProfile: updateProfileSlice,
    uploadImage: uploadImageSlice,
    payment: paymentSlice,
    registrationPayment: registrationPaymentSlice,
    checkout: checkoutSlice,
    notification: notificationSlice,
    delivery: deliverySlice,
    country: countrySlice,
    feedback: feedbackSlice,
    about: aboutSlice,
    termsAndCondition: termsAndConditionSlice,
    customerSupport: supportSlice,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
