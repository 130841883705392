import { toast } from "react-toastify"
import { axiosInstance } from "../../app/service/api"
import { ILoginForm } from "./useLoginHook"
import axios from "axios"
import { getUser } from "../../utils/helpers"

export interface LoginProcessResponse {
  userId: number
}
export interface LoginResponse {
  id: number
  fullName: string
  phoneNumber: string
  country: string
  description: string
  imageUrl: string
  status: string
  isLoggedIn?: any
  role: string
  token: string
  subscriptionStartDate: string
  subscriptionEndDate: string
}

export interface LogoutResponse {
  id: number
  fullName: string
  phoneNumber: string
  country: string
  description: string
  imageUrl: string
  status: string
  role: string
}

export const loginApi = async (formData: ILoginForm) => {
  try {
    const response = await axiosInstance.post<
      LoginResponse | LoginProcessResponse
    >("/auth/login", formData)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
    throw error
  }
}

export const logoutApi = async () => {
  const user = getUser()

  try {
    if (!user) return
    const response = await axiosInstance.put<LogoutResponse>(
      `/auth/user/${user.id}`,
      { phoneNumber: user.phoneNumber, isLoggedIn: false },
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      },
    )

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
