import { axiosInstance } from "../../app/service/api"
import { getToken } from "../../utils/helpers"

export interface CreditCategory {
  id: number
  title: string
  imageUrl: string
  description: string
  creditType: string | null
  createdAt: string
  updatedAt: string
}

export interface Credit {
  fullName: string
  address: string
  phoneNumber: string
  amount: string
  fieldOfBusiness?: string
  tradeName?: string
  creditType: string
  imageUrls: any
  isApplied: boolean
  userId: string
  creditCategoryId: string
}

export interface CreditResponse {
  id: number
  fullName: string
  phoneNumber: string
  amount: string
  fieldOfBusiness: string
  tradeName: string
  creditType: string
  imageUrls: string[]
  isApplied: boolean
  userId: string
  creditCategoryId: string
  updatedAt: string
  createdAt: string
}

export const getCreditCategoryApi = async () => {
  let token = getToken()

  try {
    const response = await axiosInstance.get<CreditCategory[]>(
      `creditCategory`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const addCreditApi = async (formData: Credit) => {
  let token = getToken()

  try {
    const response = await axiosInstance.post<CreditResponse>(
      `credit`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
