import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { axiosInstance } from "../../app/service/api"
import { toast } from "react-toastify"

export interface RegistrationPayment {
  paymentMethod: string
  paymentImageUrl: string
  userId: number
}

export interface RegistrationPaymentResponse {
  id: number
  paymentMethod: string
  paymentImageUrl: string
  userId: number
  updatedAt: string
  createdAt: string
}

export const registrationPaymentApi = async (formData: RegistrationPayment) => {
  try {
    const response = await axiosInstance.post<RegistrationPaymentResponse>(
      "registrationPayment",
      formData,
    )

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const registrationPaymentAsync = createAsyncThunk(
  "registrationPayment",
  async (formData: RegistrationPayment) => {
    try {
      const response = await registrationPaymentApi(formData)
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  },
)

interface registrationPaymentState {
  status: "idle" | "loading" | "rejected"
  error: string | undefined
  registrationPaymentResponse: null | RegistrationPayment
  isSucced: boolean
}

const initialState: registrationPaymentState = {
  status: "idle",
  error: undefined,
  registrationPaymentResponse: null,
  isSucced: false,
}

export const registrationPaymentSlice = createSlice({
  name: "registrationPayment",
  initialState,
  reducers: {
    resetRegistrationPayment: (state) => {
      state.status = "idle"
      state.error = undefined
      state.registrationPaymentResponse = null
      state.isSucced = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registrationPaymentAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(registrationPaymentAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.registrationPaymentResponse = action.payload

        toast.success(
          "Registration payment success please wait until you get granted",
          {
            position: toast.POSITION.TOP_RIGHT,
          },
        )
        state.isSucced = true
      })
      .addCase(registrationPaymentAsync.rejected, (state, action) => {
        state.status = "rejected"
        state.error = action.error.message
      })
  },
})

export const { resetRegistrationPayment } = registrationPaymentSlice.actions

export default registrationPaymentSlice.reducer
