import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { axiosInstance } from "../../app/service/api"
import { getToken } from "../../utils/helpers"
import { toast } from "react-toastify"

export interface Feedback {
  description: string
  userId: number
}

interface FeedbackResponse {
  id: number
  description: string
  userId: string
  updatedAt: string
  createdAt: string
}

export const addFeedbackApi = async (data: Feedback) => {
  const token = getToken()
  try {
    const response = await axiosInstance.post<FeedbackResponse>(
      "feedback",
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const addFeedbackAsyc = createAsyncThunk(
  "feedback",
  async (data: Feedback) => {
    try {
      const response = await addFeedbackApi(data)
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  },
)

interface FeedbackState {
  status: "idle" | "loading" | "rejected"
  error: string | undefined
  feedback: FeedbackResponse | null
}

const initialState: FeedbackState = {
  status: "idle",
  error: undefined,
  feedback: null,
}

const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(addFeedbackAsyc.pending, (state) => {
        state.status = "loading"
      })
      .addCase(addFeedbackAsyc.fulfilled, (state, action) => {
        state.status = "idle"
        state.feedback = action.payload

        toast.success("Thank you for your feedback", {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .addCase(addFeedbackAsyc.rejected, (state, action) => {
        state.status = "rejected"
        state.error = action.error.message
      })
  },
})

export default feedbackSlice.reducer
