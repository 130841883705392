import { createAsyncThunk } from "@reduxjs/toolkit"
import { getCourseByTrainingIdApi } from "./courseApi"

export const getCourseByTrainingIdAsync = createAsyncThunk(
  "course/trainingId",
  async (id: number) => {
    try {
      const response = await getCourseByTrainingIdApi(id)
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  },
)
