import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { getNotificationAsync } from "./notificationSlice"
import LoadingSpinner from "../../components/loading"
import moment from "moment"
import { Pagination } from "../../components/Pagination"
import Container from "../../components/Container"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

const Notifications: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [nextPage, setNextPage] = useState(1)

  const { notificationReponse, status } = useAppSelector(
    (state) => state.notification,
  )

  useEffect(() => {
    dispatch(getNotificationAsync(nextPage))
  }, [dispatch, nextPage])

  if (status === "loading") {
    return (
      <div className="flex items-center justify-center h-screen">
        <LoadingSpinner />
      </div>
    )
  }

  if (notificationReponse.length === 0) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-center p-10 text-2xl">{t("Notifications")}</p>
      </div>
    )
  }

  return (
    <Container>
      <div className="mt-10  mx-auto w-full max-w-screen-sm ">
        <h1 className="text-xl font-bold">{t("Notifications")}</h1>

        <div>
          {notificationReponse?.map((notification, index) => {
            const date = moment(notification.createdAt).fromNow()
            return (
              <div
                key={index}
                className="w-full bg-white px-4 py-4 rounded-lg my-4 hover:cursor-pointer hover:bg-slate-100 transition-all duration-300"
                onClick={() => {
                  if (notification.productId) {
                    navigate(`/product/${notification.productId}`)
                  }

                  if (notification.trainingId) {
                    navigate(`/training/${notification.trainingId}`)
                  }
                }}
              >
                <div>
                  <h1 className="text-xl font-light my-1 ">
                    {notification.title}
                  </h1>
                  <p className="line-clamp-4 text-ellipsis">
                    {notification.description}
                  </p>
                  <p className="text-sm font-light my-2">{date}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="flex w-full justify-end">
        <Pagination
          previous={nextPage > 1}
          next={notificationReponse?.length > 9}
          loadPreviousPage={() => setNextPage((prev) => prev - 1)}
          loadNextPage={() => setNextPage((prev) => prev + 1)}
        />
      </div>
    </Container>
  )
}

export default Notifications
