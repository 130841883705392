import { axiosInstance } from "../../app/service/api"
import { getToken } from "../../utils/helpers"

export interface TrainingCategory {
  id: number
  title: string
  imageUrl: string
  description: string
  createdAt: string
  updatedAt: string
}

export const fetchTrainingCategoryApi = async () => {
  let token = getToken()

  try {
    const response = await axiosInstance.get<TrainingCategory[]>(
      "trainingCategory",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
