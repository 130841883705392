import { axiosInstance } from "../../app/service/api"
import { getToken } from "../../utils/helpers"

export interface AboutOnBoarding {
  id: number
  videoUrl: string
  title: string
  pdfUrl: string
  subTitle: string
  createdAt: string
  updatedAt: string
}

export const aboutOnBoardingApi = async () => {
  let token = getToken()

  try {
    const response = await axiosInstance.get<AboutOnBoarding[]>(
      "aboutOnBoarding",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
