import { createAsyncThunk } from "@reduxjs/toolkit"
import { IRegisterForm } from "./useRegisterHook"
import { registerApi } from "./registerAPI"

export const registerAsync = createAsyncThunk(
  "register",
  async (formData: IRegisterForm) => {
    try {
      const response = await registerApi(formData)
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  },
)
