import { getCompleteImageUrl } from "../../utils/helpers"

interface TrainingCardProps {
  image: string
  onRoute: () => void
  title: string
  description: string
}
const TrainingCard = ({
  image,
  onRoute,
  title,
  description,
}: TrainingCardProps) => {
  return (
    <>
      <button
        className="md:flex shadow-md p-4  rounded-lg bg-white mt-6 w-full"
        onClick={onRoute}
      >
        <div className="md:flex">
          <img
            src={getCompleteImageUrl(image)}
            alt="power"
            className="object-cover h-36 w-full md:w-44 rounded-lg"
          />

          <div className=" mt-4 ml-4">
            <h2 className="font-bold text-xl text-start">{title}</h2>
            <p className="line-clamp-3 text-ellipsis text-justify">
              {description}
            </p>
          </div>
        </div>
        {/* <img
          src={`${baseUrl}/uploads/${image}`}
          alt="power"
          className="object-contain h-36 w-full md:w-80 rounded-lg"
        />

        <div className=" mt-4 ml-4">
          <h2 className="font-bold text-xl text-start">{title}</h2>
          <p className="line-clamp-3 text-ellipsis text-justify">
            {description}
          </p>
        </div> */}
      </button>
    </>
  )
}

export default TrainingCard
