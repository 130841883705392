import Container from "../../components/Container"
import LoadingSpinner from "../../components/loading"
import { useRegisterHook } from "./useRegisterHook"
import PETextinput from "../../components/Input/Input"
import PETextarea from "../../components/PETextarea"
import PEButton from "../../components/Input/PEPutton"
import { Link, useNavigate } from "react-router-dom"
import UploadMultipleImage from "../ImageUpload/uploadMultipleImage"
import { useTranslation } from "react-i18next"
import CountryDropdown from "../Country/Country"

export default function Register() {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const {
    handleSubmit,
    onSubmit,
    register,
    setIsAgreed,
    errors,
    status,
    isAgreed,
    handleMultipleFile,
    mstatus,
    image,
    handleRemoveImage,
    uploadImg,
    muploadResult,
  } = useRegisterHook()

  return (
    <Container>
      <div className="mt-10 mx-auto w-full max-w-screen-sm ">
        <p className="text-center my-2 text-2xl">
          {t("Register for Power Ethiopia")}
        </p>

        <div className="p-4 rounded-lg border-2 border-neutral-300 mb-6">
          <p className="text-justify text-grayText">
            {t(
              "Provide your full information to register for power Ethiopia before proceeding to payment",
            )}
          </p>
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-6 bg-white p-4 rounded-lg shadow-xl md:p-10 "
        >
          <PETextinput
            type={"text"}
            name={"fullName"}
            label={t("Full name")}
            placeholder={t("Enter your full name")}
            rules={{ required: t("Name is required") }}
            register={register}
            errors={errors}
          />

          <PETextinput
            type={"number"}
            name={"phoneNumber"}
            label={t("Phone number")}
            placeholder={t("Enter your phone number")}
            rules={{ required: t("Phone number is required") }}
            register={register}
            errors={errors}
          />

          <p>{t("City")}</p>
          <CountryDropdown />

          {/* <PETextinput
            type={"text"}
            name={"country"}
            label={t("Country")}
            placeholder={t("Enter your Country")}
            rules={{ required: t("Country is required") }}
            register={register}
            errors={errors}
          /> */}

          <PETextinput
            type={"password"}
            name={"password"}
            label={t("Password")}
            placeholder={t("Enter your password")}
            rules={{ required: t("Password is required") }}
            register={register}
            errors={errors}
          />

          <PETextarea
            name={"description"}
            register={register}
            placeholder={t("Enter your job description")}
            label={t("Description")}
            errors={errors}
          />

          <div className="text-justify text-neutral-800 text-[12px] font-normal ">
            {t(
              "Please upload your ID documents or passport so we can verify your identity",
            )}
          </div>
          {image.length > 0 && (
            <div className="flex ">
              {image.map((img, index) => {
                const imageUrl = URL.createObjectURL(img)
                return (
                  <div className="relative" key={index}>
                    <img src={imageUrl} alt="" height={100} width={100} />
                    <button
                      className="absolute top-0 right-0 h-6 w-6 bg-white shadow-md rounded-full text-red-500"
                      onClick={() => handleRemoveImage(index)}
                    >
                      X
                    </button>
                  </div>
                )
              })}
            </div>
          )}
          {mstatus === "loading" ? (
            <LoadingSpinner />
          ) : (
            <>
              {image.length > 0 ? (
                <PEButton
                  name={"Upload images"}
                  type={"button"}
                  onClick={uploadImg}
                />
              ) : (
                <UploadMultipleImage onImagesChange={handleMultipleFile} />
              )}
            </>
          )}
          <div className="mt-6 flex text-grayText text-sm text-justify ">
            <input
              type="checkbox"
              className="mx-4"
              onChange={(e) => setIsAgreed(e.target.checked)}
              checked={isAgreed}
            />
            <Link
              to="/termsAndConditions"
              className="underline underline-offset-2"
              target="_blank"
            >
              {t("Agree to our terms and conditions")}
            </Link>
          </div>

          {status === "loading" ? (
            <LoadingSpinner />
          ) : (
            <PEButton
              disabled={muploadResult === null}
              name={t("Procced to payment")}
              type="submit"
            />
          )}

          <div className="text-center">
            <p className="text-xl text-primary">
              {t("Already have an accoount")} ?
            </p>
            <PEButton
              name={t("Login")}
              type="button"
              onClick={() => navigate("/login")}
            />
          </div>
        </form>
      </div>
    </Container>
  )
}
