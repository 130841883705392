import { createAsyncThunk } from "@reduxjs/toolkit"
import { Checkout, addCheckoutApi, getCheckoutApi } from "./checkoutApi"

export const addCheckoutAsync = createAsyncThunk(
  "checkout",
  async (formData: Checkout) => {
    try {
      const response = await addCheckoutApi(formData)
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  },
)

export const getCheckoutAsync = createAsyncThunk(
  "checkoutlist",
  async (page: number) => {
    try {
      const response = await getCheckoutApi(page)
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  },
)
