import {
  DeepMap,
  FieldError,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister,
} from "react-hook-form"
import get from "lodash.get"
import { ErrorMessage } from "@hookform/error-message"

interface InputProps<TFormValues extends FieldValues> {
  name: Path<TFormValues>
  register: UseFormRegister<TFormValues>
  rules?: RegisterOptions
  errors?: Partial<DeepMap<TFormValues, FieldError>>
  placeholder?: string
  accept?: string
  id?: string
  className?: string
  label: string
}

const PETextarea = <TFormValues extends FieldValues>({
  name,
  register,
  rules,
  errors,
  placeholder,
  id,
  label,
}: InputProps<TFormValues>) => {
  const defaultInputSyle =
    "p-2 px-4 text-start text-primary border border-primary w-full placeholder:text-xs placeholder:text-start md:text-left placeholder:md:text-left focus:outline-none rounded-md focus:ring-primary focus:ring-1 "
  const errorInputStyle =
    "p-2 px-4 text-start text-white border border-error w-full placeholder:text-xs placeholder:text-start md:text-left placeholder:md:text-left focus:outline-none rounded-md focus:ring-error focus:ring-1"

  const errorMessages = get(errors, name)
  const hasError = !!(errors && errorMessages)

  return (
    <div>
      <label
        htmlFor="name"
        className="block text-sm font-medium leading-6 text-gray-900 mb-4"
      >
        {label}
      </label>
      <div className="flex">
        <textarea
          id={id}
          placeholder={placeholder}
          className={hasError ? errorInputStyle : defaultInputSyle}
          {...register(name, rules)}
        />
      </div>
      <ErrorMessage
        errors={errors}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        name={name as any}
        render={({ message }) => (
          <p className="mt-1 font-serif text-sm text-left block text-error">
            {message}
          </p>
        )}
      />
    </div>
  )
}

export default PETextarea
